import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Row, Col, Progress, CardBody } from "reactstrap"

const TravelProgressBar = ({ travelDetail }) => {
  const [statusPercentage, setStatusPercentage] = useState()
  const travelStatus = travelDetail?.status

  const statusBar = () => {
    if (travelStatus == "waiting_for_offers") {
      return setStatusPercentage(7)
    } else if (travelStatus == "offer_accepted") {
      return setStatusPercentage(16)
    } else if (travelStatus == "pending_first_step") {
      return setStatusPercentage(33)
    } else if (
      travelStatus == "in_validation_first_step" ||
      travelStatus == "failed_first_step"
    ) {
      return setStatusPercentage(43)
    } else if (travelStatus == "pending_start") {
      return setStatusPercentage(50)
    } else if (travelStatus == "in_progress") {
      return setStatusPercentage(67)
    } else if (travelStatus == "pending_last_step") {
      return setStatusPercentage(83)
    } else if (
      travelStatus == "in_validation_last_step" ||
      travelStatus == "failed_last_step"
    ) {
      return setStatusPercentage(93)
    } else if (travelStatus == "waiting_for_review") {
      return setStatusPercentage(97)
    } else if (travelStatus == "completed") {
      return setStatusPercentage(100)
    } else {
      return setStatusPercentage(0)
    }
  }
  useEffect(() => {
    statusBar()
  })
  return (
    <CardBody style={{ paddingLeft: "0", paddingRight: "0", margin: "49px" }}>
      <div className="position-relative">
        <Col>
          <Row style={{ textAlign: "center" }}>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i className="bx bx-search text m-0"></i>
              <i
                className="translate-middle bg-success rounded-circle"
                style={{
                  width: "8px",
                  height: "8px",
                  marginTop: "17px",
                  marginBottom: "10px",
                  zIndex: 3,
                }}
              ></i>
              <label style={{ height: "30px" }}>Recibiendo ofertas</label>
            </Col>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i className="bx bx-search m-0"></i>
              <i
                className="translate-middle bg-success rounded-circle"
                style={{
                  width: "8px",
                  height: "8px",
                  marginTop: "17px",
                  marginBottom: "10px",
                  zIndex: 3,
                }}
              ></i>
              <label style={{ height: "30px" }}>Oferta aceptada</label>
            </Col>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i className="bx bx-credit-card m-0"></i>
              <i
                className="translate-middle bg-success rounded-circle"
                style={{
                  width: "8px",
                  height: "8px",
                  marginTop: "17px",
                  marginBottom: "10px",
                  zIndex: 3,
                }}
              ></i>
              <label style={{ height: "30px" }}>Primer Pago</label>
            </Col>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i className="bx bx-check-circle m-0"></i>
              <i
                className="translate-middle bg-success rounded-circle"
                style={{
                  width: "8px",
                  height: "8px",
                  marginTop: "17px",
                  marginBottom: "10px",
                  zIndex: 3,
                }}
              ></i>
              <label style={{ height: "30px" }}>Viaje aceptado</label>
            </Col>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i className="bx bxs-truck m-0"></i>
              <i
                className="translate-middle bg-success rounded-circle"
                style={{
                  width: "8px",
                  height: "8px",
                  marginTop: "17px",
                  marginBottom: "10px",
                  zIndex: 3,
                }}
              ></i>
              <label style={{ height: "30px" }}> Inicio del viaje</label>
            </Col>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i className="bx bxs-map top-0 start-50 m-0"></i>
              <i
                className="translate-middle bg-success rounded-circle"
                style={{
                  width: "8px",
                  height: "8px",
                  marginTop: "17px",
                  marginBottom: "10px",
                  zIndex: 3,
                }}
              ></i>
              <label style={{ height: "30px" }}>Viaje finalizado</label>
            </Col>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <i className="bx bx-credit-card  m-0"></i>
              <i
                className="translate-middle bg-success rounded-circle"
                style={{
                  width: "8px",
                  height: "8px",
                  marginTop: "17px",
                  marginBottom: "10px",
                  zIndex: 3,
                }}
              ></i>
              <label style={{ height: "30px" }}>Segundo pago</label>
            </Col>
          </Row>
        </Col>
        <Progress
          value={statusPercentage}
          color="success"
          style={{
            height: "4px",
            position: "absolute",
            top: "37px",
            left: "6%",
            width: "87.5%",
            backgroundColor: "#959397",
            zIndex: 1,
          }}
        ></Progress>
      </div>
    </CardBody>
  )
}
TravelProgressBar.propTypes = {
  travelDetail: PropTypes.object,
}
export default TravelProgressBar
