import React, { useState } from "react"
import { useDispatch } from "react-redux"

import PropTypes from "prop-types"

import { Button, Col, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { Link } from "react-scroll"

import UserIrregularityModal from "./UserIrregularityModal"
import UserSuccessModal from "./UserSuccessModal"
import UserEditModal from "./UserEditModal"

import UserAvatar from "components/UserAvatar/UserAvatar"
import "./UserInfo.scss"

import { updateClient } from "store/actions"

const UserInfo = ({ userData, userDetail, errors }) => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()

  // ------------------------------------------------------------------------
  // State
  // ------------------------------------------------------------------------

  const [modal, setModal] = useState(false)
  const [modalValid, setModalValid] = useState(false)
  const [modalEdit, setModalEdit] = useState(false)

  // ------------------------------------------------------------------------
  // Functions
  // ------------------------------------------------------------------------

  const handleOnClick = () => {
    toggle()
  }
  const handleOnClickValid = () => {
    toggleValid()
  }
  const handleOnClickEdit = () => {
    toggleEdit()
  }
  const toggle = () => {
    setModal(!modal)
  }
  const toggleValid = () => {
    setModalValid(!modalValid)
  }
  const toggleEdit = () => {
    setModalEdit(!modalEdit)
  }

  const handleSubmitForm = (e, data) => {
    if(modalEdit) {
      dispatch(updateClient(userDetail.id, {user: data}))
    }

    if (errors === null) {
      toggleEdit()
    }
  }

  return (
    <div className="user-info w-100">
      <div className="sub-container">
        <UserAvatar userDetail={userDetail} />
        <div>
          <div>
            <Row>
              <Col>
                <Button
                  type="button"
                  color="warning"
                  onClick={() => handleOnClickEdit()}
                >
                  Editar informacion personal
                </Button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="personal-info">
        <Row>
          <Col sm="4">
            <h2>
              Información personal
              <i
                className={
                  userData.userStatus === "validated"
                    ? "mdi mdi-clock text-" + userDetail.document_status
                    : "bx bxs-check-circle text-" + userDetail.document_status
                }
              ></i>
            </h2>
          </Col>
          <Col sm="3"></Col>
          <Col sm="3">
            <p className="font-size-14">¿La información es correcta?</p>
          </Col>
          <Col sm="1">
            <Link to="#" className="text-empty" onClick={() => handleOnClick()}>
              <i
                className={
                  userDetail.general_information_status === "sended"
                    ? "bx bxs-x-circle text-" +
                      userDetail.general_information_status
                    : "bx bx-x-circle"
                }
              ></i>
            </Link>
          </Col>
          <Col>
            <Link
              to="#"
              className="text-empty"
              onClick={() => handleOnClickValid()}
            >
              <i
                className={
                  userDetail.general_information_status === "validated"
                    ? "bx bxs-check-circle text-" +
                      userDetail.general_information_status
                    : "bx bx-check-circle"
                }
              ></i>
            </Link>
          </Col>
        </Row>

        <UserIrregularityModal
          modal={modal}
          toggle={toggle}
          id={userDetail.id}
        />

        <UserSuccessModal
          modal={modalValid}
          toggle={toggleValid}
          id={userDetail.id}
        />

        <UserEditModal
          modal={modalEdit}
          toggle={toggleEdit}
          userDetail={userDetail}
          handleSubmit={ handleSubmitForm }
        />

        <div className="personal-info-container">
          {Object.keys(userData)?.map((key, index) => (
            <div key={index}>
              <Col>
                <Row className="dat">{t(key)}</Row>
                <Row>{t(userData[key])}</Row>
              </Col>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

UserInfo.propTypes = {
  userData: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string,
      text: PropTypes.string,
      userStatus: PropTypes.string,
    })
  ),
  userDetail: PropTypes.object,
  errors: PropTypes.object,
}

export default UserInfo
