import React, {useEffect} from "react"
import PropTypes from "prop-types"

import { connect } from "react-redux"
import { Row, Col, Card, CardBody } from "reactstrap"

import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react"

import TravelDateLabel from "./TravelDateLabel"
import TravelDescriptionLabel from "./TravelDescriptionLabel"

const LoadingContainer = () => <div>Loading...</div>

const DateLabelContainer = ({ children }) => (
  <Row
    style={{
      marginBottom: "50px",
      marginTop: "45px",
    }}
  >
    <div
      className="text-light w-100"
      style={{
        backgroundColor: "#E6E3E9",
        display: "flex",
        borderRadius: "8px",
        height: "56px",
        width: "252px",
        padding: 0,
      }}
    >
      {children}
    </div>
  </Row>
)

DateLabelContainer.propTypes = {
  children: PropTypes.node,
}

const CardProgressTravel = ({ travelDetail, google }) => {
  const { start_address, end_address } = travelDetail

  useEffect(() => {
    if(document.getElementById('gmap')) {

      const map = new google.maps.Map(document.getElementById('gmap'), {
        zoom: 8,
        center: new google.maps.LatLng(19.432608, -99.133209),
        mapTypeId: google.maps.MapTypeId.ROADMAP
      });

      if (start_address && end_address) {
        const bounds = new google.maps.LatLngBounds();

        if (start_address.latitude && start_address.longitude) {
          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(start_address.latitude, start_address.longitude),
            map: map,
          });

          bounds.extend(marker.position);

          const infowindow = new google.maps.InfoWindow({
            content: "<h5>Dirección de origen</h5>",
          });

          marker.addListener("click", () => {
            infowindow.open({ anchor: marker, map, shouldFocus: false,});
          });
        }

        if (end_address.latitude && end_address.longitude) {
          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(end_address.latitude, end_address.longitude),
            map: map,
          });

          bounds.extend(marker.position);

          const infowindow = new google.maps.InfoWindow({
            content: "<h5>Dirección de destino</h5>",
          });

          marker.addListener("click", () => {
            infowindow.open({ anchor: marker, map, shouldFocus: false,});
          });
        }

        map.fitBounds(bounds);
      }

      const listener = google.maps.event.addListener(map, "gmap", function () {
        map.setZoom(3);
        google.maps.event.removeListener(listener);
      });
    }
  });


  return (
    <React.Fragment>
      <div className="user-info w-100 position-relative" id="travelInProgress">
      {
        travelDetail.client_start_accepted_at ?
        <div className="container-fluid">
          <Row className="mt-5">
            <Col lg={4}>
              <DateLabelContainer>
                <TravelDateLabel
                  state="client_accepted_offer_at"
                  date={travelDetail.client_accepted_offer_at}
                />
                <TravelDescriptionLabel
                  state="client_accepted_offer_at"
                  date={travelDetail.client_accepted_offer_at}
                />
              </DateLabelContainer>

              <DateLabelContainer>
                <TravelDateLabel
                  state={"client_start_accepted_at"}
                  date={travelDetail?.client_start_accepted_at}
                />
                <TravelDescriptionLabel
                  state={"client_start_accepted_at"}
                  date={travelDetail.client_start_accepted_at}
                />
              </DateLabelContainer>

              <DateLabelContainer>
                <TravelDateLabel
                  state="client_finish_accepted_at"
                  date={travelDetail.client_finish_accepted_at}
                />
                <TravelDescriptionLabel
                  state={"client_finish_accepted_at"}
                  date={travelDetail.client_finish_accepted_at}
                />
              </DateLabelContainer>
            </Col>

            <Col lg={8}>
              <Card>
                <CardBody>
                  <div id="gmap" className="gmaps" style={{ position: "relative" }}></div>
                </CardBody>
              </Card>
            </Col>

          </Row>
        </div>
        : <Row className="mt-5" style={{ fontWeight: "600", color: "#757575"}}>
          Información aún no disponible
        </Row>
      }
      </div>
    </React.Fragment>
  )
}

CardProgressTravel.propTypes = {
  google: PropTypes.object,
  travelDetail: PropTypes.object,
}

export default connect(
  null,
  {}
)(
  GoogleApiWrapper({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    LoadingContainer: LoadingContainer,
    v: "3",
  })(CardProgressTravel)
)
