export const GET_TRIPS = "GET_TRIPS"
export const GET_TRIPS_SUCCESS = "GET_TRIPS_SUCCESS"
export const GET_TRIPS_FAIL = "GET_TRIPS_FAIL"

export const GET_TRIP = "GET_TRIP"
export const GET_TRIP_SUCCESS = "GET_TRIP_SUCCESS"
export const GET_TRIP_FAIL = "GET_TRIP_FAIL"

export const PUT_TRIP_PAYMENT = "PUT_TRIP_PAYMENT"
export const PUT_TRIP_PAYMENT_SUCCESS = "PUT_TRIP_PAYMENT_SUCCESS"
export const PUT_TRIP_PAYMENT_FAIL = "PUT_TRIP_PAYMENT_FAIL"

export const SET_ERROR = "SET_ERROR"

export const SEARCH_TRIP = "SEARCH_TRIP"
export const SEARCH_TRIP_SUCCESS = "SEARCH_TRIP_SUCCESS"
export const SEARCH_TRIP_FAIL = "SEARCH_TRIP_FAIL"

export const FILTER_TRIP = "FILTER_TRIP"
export const FILTER_TRIP_SUCCESS = "FILTER_TRIP_SUCCESS"
export const FILTER_TRIP_FAIL = "FILTER_TRIP_FAIL"
