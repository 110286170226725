import {
  GET_DOCUMENTS_SUCCESS,
  GET_DOCUMENTS_FAIL,

  PUT_DOCUMENT_FAIL,
  PUT_DOCUMENT_SUCCESS,

  SET_ERROR,
} from "./actionTypes"

const INIT_STATE = {
  documents: [],
  loading: true,
  errors: null,
}

const Documents = (state = INIT_STATE, action) => {
  switch (action.type) {

    // ----------------------------------------------------------------------------
    // List drivers
    // ----------------------------------------------------------------------------

    case GET_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: action.payload.data,
        loading: false
      }

    case GET_DOCUMENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    // ----------------------------------------------------------------------------
    // Update document
    // ----------------------------------------------------------------------------

    case PUT_DOCUMENT_SUCCESS:
      const indexChat = state.documents.findIndex(c => c.id === action.payload.data.id)
      const newArray = [...state.documents];

      newArray[indexChat] = action.payload.data

      return {
        ...state,
        documents: newArray,
        loading: false,
        errors: null
      }

    case PUT_DOCUMENT_FAIL:
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      }

    // ----------------------------------------------------------------------------

    case SET_ERROR:
      return {
        ...state,
        errors: action.payload,
      }
      break

    default:
      return state
  }
}

export default Documents
