import React, { useState } from "react"
import PropTypes from "prop-types"

import {
  Row,
  Col,
  Button,
  ButtonGroup,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  ButtonDropdown
} from "reactstrap"

import { useDispatch } from "react-redux"
import { filterTrip } from "store/actions"

const TravelHeaderList = ({ trips }) => {
  const dispatch = useDispatch()
  const [openFiltros, setOpenFiltros] = useState(false)

  const filter = (value) => {
    dispatch(filterTrip(value))
  }

  return (
    <div>
      <Row className="mb-2">
        <Col sm="12" md="2">
          <div className="search-box me-2 mb-2 d-inline-block">
            <h5>Listado de Viajes</h5>
            {/* <p>+12 nuevos viajes</p> */}
          </div>
        </Col>
        <Col sm="12" md="5">
          <ButtonDropdown
            isOpen={openFiltros}
            toggle={() => setOpenFiltros(!openFiltros)}

          >
            <DropdownToggle
              caret
              style={{background: "#00796b"}}
              className="btn btn-success dropdown-filters"
            >
              Filtrar por status
              <i className="mdi mdi-chevron-down" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem
                style={{color: "#004d40"}}
                onClick={ () => filter("status=created") }>
                  Creado
              </DropdownItem>
              <DropdownItem
                style={{color: "#004d40"}}
                onClick={ () => filter("status=offer_accepted") }>
                  Oferta aceptada
              </DropdownItem>
              <DropdownItem
                style={{color: "#004d40"}}
                onClick={ () => filter("status=pending_start") }>
                  Por comenzar
              </DropdownItem>
              <DropdownItem
                style={{color: "#004d40"}}
                onClick={ () => filter("status=in_progress") }>
                  En progreso
              </DropdownItem>
              <DropdownItem
                style={{color: "#004d40"}}
                onClick={ () => filter("status=completed") }>
                  Completado
              </DropdownItem>
              <DropdownItem
                divider />
              <DropdownItem
                style={{color: "red"}}
                onClick={ () => filter("status=failed_first_step") }>
                Primer pago declinado
              </DropdownItem>
              <DropdownItem
                style={{color: "red"}}
                onClick={ () => filter("status=failed_last_step") }>
                Falló último pago
              </DropdownItem>
              <DropdownItem
                style={{color: "red"}}
                onClick={ () => filter("status=cancelled") }>
                Cancelado
              </DropdownItem>
              <DropdownItem
                divider />
              <DropdownItem
                style={{color: "#1565c0"}}
                onClick={ () => filter("status=waiting_for_offers") }>
                  Esperando ofertas
                </DropdownItem>
              <DropdownItem
                style={{color: "#1565c0"}}
                onClick={ () => filter("status=pending_first_step") }>
                  Esperando primer pago
                </DropdownItem>
              <DropdownItem
                style={{color: "#1565c0"}}
                onClick={ () => filter("status=in_validation_first_step") }>
                  Validando primer pago
                </DropdownItem>
              <DropdownItem
                style={{color: "#1565c0"}}
                onClick={ () => filter("status=pending_last_step") }>
                  Último pago pendiente
                </DropdownItem>
              <DropdownItem
                style={{color: "#1565c0"}}
                onClick={ () => filter("status=in_validation_last_step") }>
                  Validando último pago
                </DropdownItem>
              <DropdownItem
                style={{color: "#1565c0"}}
                onClick={ () => filter("status=waiting_for_review") }>
                  Esperando review
                </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </Col>

        <Col sm="12" md="3">
          <ButtonGroup>
            <Button
              color="warning"
              onClick={ () => filter("date=first_day_month") }
            >
              Mes
            </Button>
            <Button
              color="warning"
              onClick={ () => filter("date=first_day_week") }
            >
              Semana
            </Button>
            <Button
              color="warning"
              onClick={ () => filter("date=today") }
            >
              Dia
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </div>
  )
}

TravelHeaderList.propTypes = {
  trips: PropTypes.array,
}

export default TravelHeaderList
