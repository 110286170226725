import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import MetaTags from "react-meta-tags"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider from "react-bootstrap-table2-toolkit"


//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { getVehicles, getVehicleTypes, createVehicle, updateVehicle, setError } from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import TableHeader from "./tableHeader"
import ModalVehicle from "./modal"

const Vehicles = props => {
  const dispatch = useDispatch()

  // ------------------------------------------------------------------------
  // State
  // ------------------------------------------------------------------------

  const [currentVehicle, setCurrentVehicle] = useState({})
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const { vehicles, vehicle_types, errors } = useSelector(state => ({
    vehicles: state.vehicles.vehicles,
    vehicle_types: state.vehicles.vehicle_types,
    errors: state.vehicles.errors
  }))

  useEffect(() => {
    dispatch(getVehicles())
  }, [getVehicles])

  useEffect(() => {
    dispatch(getVehicleTypes())
  }, [getVehicleTypes])

  // ------------------------------------------------------------------------
  // Setup Base table
  // ------------------------------------------------------------------------

  const pageOptions = {
    sizePerPage: 10,
    totalSize: vehicles?.length,
    custom: true,
  }

  const selectRow = { mode: "checkbox" }
  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const tableColumns = toggleModal => [
    {
      dataField: "configuration",
      text: "Configuración",
      sort: true,
      formatter: (cellContent, vehicle) => (
        <div>{ vehicle.configuration }</div>
      ),
    },
    {
      dataField: "charge",
      text: "Peso Max.",
      sort: true,
      formatter: (cellContent, vehicle) => (
        <div>{ vehicle.charge } kg</div>
      ),
    },
    {
      dataField: "m3",
      text: "m3",
      sort: true,
      formatter: (cellContent, vehicle) => (
        <div>{ vehicle.m3 } </div>
      ),
    },
    {
      dataField: "length",
      text: "Altura",
      sort: true,
      formatter: (cellContent, vehicle) => (
        <div>{ vehicle.length } m.</div>
      ),
    },
    {
      dataField: "width",
      text: "Ancho",
      sort: true,
      formatter: (cellContent, vehicle) => (
        <div>{ vehicle.width } m.</div>
      ),
    },
    {
      dataField: "height",
      text: "Alto",
      sort: true,
      formatter: (cellContent, vehicle) => (
        <div>{ vehicle.height } m.</div>
      ),
    },
    {
      dataField: "type",
      text: "Tipo",
      sort: true,
      formatter: (cellContent, vehicle) => (
        <div>{ vehicle.vehicle_type.name } </div>
      ),
    },
    // ------------------------------------------------------------------------
    // Actions
    // ------------------------------------------------------------------------
    {
      dataField: "action",
      isDummyField: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, vehicle) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              onClick={() => handleEdit(vehicle)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>

            {/* { currentUser.id === vehicle.id ? "" :
              <Link
                to="#"
                className="text-danger"
                onClick={() => handleDelete(vehicle.id)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            } */}
          </div>
        </>
      ),
    },
  ]

  // ------------------------------------------------------------------------
  // Functions
  // ------------------------------------------------------------------------

  const toggle = () => {
    setModal(!modal)
  }

  const handleSubmitForm = (e, data) => {
    if(isEdit) {
      dispatch(updateVehicle(currentVehicle.id, {vehicle: data}))
    } else {
      if (data.password === data.password_confirm) {
        dispatch(createVehicle({vehicle: data}))
      } else {
        dispatch(setError({ detail: {password: ["Las contraseñas no coinciden"]}} ))
      }
    }

    if (errors === null) {
      setModal(false)
    } else {
      setModal(true)
    }
  }

  const handleEdit = vehicle => {
    setCurrentVehicle(vehicle)
    setIsEdit(true)
    toggle()
  }

  const handleCreate = () => {
    setCurrentVehicle({})
    setIsEdit(false)
    toggle()
  }

  // const handleDelete = (id) => {
  //   dispatch(deleteVehicle(id))
  // }

  // ------------------------------------------------------------------------
  // Main Render
  // ------------------------------------------------------------------------

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags><title>Chat | Infast - Consola</title></MetaTags>
        <Container fluid>
          <Breadcrumbs title="Infast" breadcrumbItem="Vehiculos" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={tableColumns(toggle)}
                    data={vehicles}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={vehicles}
                        columns={tableColumns(toggle)}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <TableHeader handleCreate={handleCreate} />
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    // ref={node}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ModalVehicle
        vehicle={currentVehicle}
        vehicle_types={vehicle_types}
        modal={modal}
        toggle={toggle}
        isEdit={isEdit}
        handleSubmit={handleSubmitForm}
        errors={errors}
      />

    </React.Fragment>
  )
}

export default Vehicles
