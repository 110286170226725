export const GET_CLIENTS = "GET_CLIENTS"
export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS"
export const GET_CLIENTS_FAIL = "GET_CLIENTS_FAIL"

export const GET_CLIENT = "GET_CLIENT"
export const GET_CLIENT_SUCCESS = "GET_CLIENT_SUCCESS"
export const GET_CLIENT_FAIL = "GET_CLIENT_FAIL"

export const PUT_CLIENT = "PUT_CLIENT"
export const PUT_CLIENT_SUCCESS = "PUT_CLIENT_SUCCESS"
export const PUT_CLIENT_FAIL = "PUT_CLIENT_FAIL"

export const SEARCH_CLIENT = "SEARCH_CLIENT"
export const SEARCH_CLIENT_SUCCESS = "SEARCH_CLIENT_SUCCESS"
export const SEARCH_CLIENT_FAIL = "SEARCH_CLIENT_FAIL"

export const FILTER_CLIENT = "FILTER_CLIENT"
export const FILTER_CLIENT_SUCCESS = "FILTER_CLIENT_SUCCESS"
export const FILTER_CLIENT_FAIL = "FILTER_CLIENT_FAIL"

export const SET_ERROR = "SET_ERROR"
