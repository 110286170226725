import React, { useState } from "react"

import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

import PropTypes from "prop-types"


const modalAdmin = ({ admin, modal, toggle, isEdit, handleSubmit, errors }) => {
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          {isEdit ? "Editar Analista" : "Agregar Analista"}
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleSubmit}>
            <Row form>
              <Col className="col-12">
                <div className="mb-3">
                  <AvField
                    name="names"
                    label="Nombres *"
                    type="text"
                    errorMessage="Campo inválido"
                    validate={{
                      required: { value: true },
                    }}
                    value={admin.names || ""}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="col-6">
                <div className="mb-3">
                  <AvField
                    name="first_surname"
                    label="Apellido Paterno *"
                    type="text"
                    errorMessage="Campo inválido"
                    validate={{
                      required: { value: true },
                    }}
                    value={admin.first_surname || ""}
                  />
                </div>
              </Col>
              <Col className="col-6">
                <div className="mb-3">
                  <AvField
                    name="second_surname"
                    label="Apellido Materno *"
                    type="text"
                    errorMessage="Campo inválido"
                    validate={{
                      required: { value: true },
                    }}
                    value={admin.second_surname || ""}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="col-6">
                <div className="mb-3">
                  <AvField
                    name="email"
                    label="Email *"
                    type="email"
                    errorMessage="Campo inválido"
                    validate={{
                      required: { value: true },
                    }}
                    value={admin.email || ""}
                  />
                </div>
              </Col>
              <Col className="col-6">
                <div className="mb-3">
                  <AvField
                    name="phone"
                    label="Teléfono *"
                    type="text"
                    errorMessage="Campo inválido"
                    validate={{
                      required: { value: true },
                    }}
                    value={admin.phone || ""}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="col-6">
                <div className="mb-3">
                  <AvField
                    name="password"
                    label={"Contraseña" + (isEdit ? "" : " *")}
                    type="password"
                    errorMessage="Campo inválido"
                    validate={{
                      required: { value: !isEdit },
                    }}
                  />
                </div>
              </Col>
              <Col className="col-6">
                <div className="mb-3">
                  <AvField
                    name="password_confirm"
                    label={"Confirmar contraseña" + (isEdit ? "" : " *")}
                    type="password"
                    errorMessage="Campo inválido"
                    validate={{
                      required: { value: !isEdit },
                    }}
                  />
                </div>
              </Col>
              <AvField type="hidden" name="type" value="admin" />
            </Row>

            { errors !== null ? <p className="alert-danger">{ errors.detail[Object.keys(errors.detail)[0]] }</p> : ""}

            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-rounded btn-danger w-lg"
                    onClick={() => toggle() }
                  >
                    Cancelar
                  </button>
                  <button type="submit" className="btn btn-rounded btn-success w-lg save-user gap-2">
                    Guardar
                  </button>
                </div>
              </Col>
            </Row>

          </AvForm>
        </ModalBody>
      </Modal>
    </div>
  )
}
modalAdmin.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  isEdit: PropTypes.bool,
  admin: PropTypes.object,
  handleSubmit: PropTypes.func,
  errors: PropTypes.object,
}
export default modalAdmin
