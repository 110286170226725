import React from "react"
import PropTypes from "prop-types"

import { Table } from "reactstrap"
import TravelContentTable from "./TableContent"

const TravelHistory = ({ travelDetail }) => {


  return (
    <Table>
      <TravelContentTable travelDetail={travelDetail} />
    </Table>
  )
}

TravelHistory.propTypes = {
  travelDetail: PropTypes.object,
}

export default TravelHistory
