import PropTypes from "prop-types"
import React from "react"
import * as moment from "moment"

const TravelDateLabel = ({ state, date }) => {
  const handleValidHour = date => moment(new Date(date)).format("HH:MM a")

  const getColorByState = () => {
    if (state === "client_accepted_offer_at") {
      return "#FFA400"
    }
    if (state === "client_start_accepted_at") {
      return "#77B610"
    }
    return "#E23320"
  }

  const getValidDate = () => {
    if (!date) return "hh:mm AM"
    if (state === "client_accepted_offer_at") {
      return handleValidHour(date)
    }
    if (state === "client_start_accepted_at") {
      return handleValidHour(date)
    }
    if (state === "client_finish_accepted_at") {
      return handleValidHour(date)
    }
  }

  return (
    <div
      className="text-light"
      style={{
        backgroundColor: getColorByState(),
        display: "flex",
        width: "46px",
        height: "56px",
        fontSize: "12px",
        textAlign: "center",
        borderRadius: "8px  0px 0px 8px",
        alignItems: "center",
        marginRight: "8px"
      }}
    >
      {getValidDate()}
    </div>
  )
}

TravelDateLabel.propTypes = {
  state: PropTypes.string,
  date: PropTypes.string,
}

export default TravelDateLabel
