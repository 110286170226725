import React from "react"

import { Row, Col, Button } from "reactstrap"

import PropTypes from "prop-types"

const ClientHeader = ({ handleCreate }) => {
  return (
    <div>
      <Row className="mb-2">
        <Col sm="8">
          <div className="search-box me-2 mb-2 d-inline-block">
            <h5>Lista de Analistas</h5>
          </div>
        </Col>

        <Col sm="4">
          <div>
            <Button
              type="button"
              color="primary"
              className="btn-lg btn-right mb-2 me-2"
              onClick={() => handleCreate() }
            >
              +
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

ClientHeader.propTypes = {
  handleCreate: PropTypes.func,
  t: PropTypes.any,
}

export default ClientHeader
