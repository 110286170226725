import React, { useState } from "react"

import { Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"

import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

const DriverEditModal = ({ driverDetail, modal, toggle, handleSubmit }) => {
  const { t, i18n } = useTranslation()

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          {"Editar " + t(driverDetail.type)}
        </ModalHeader>

        <ModalBody>
          <AvForm onValidSubmit={handleSubmit}>
            <Row form>
              <Col className="col-12">
                <div className="mb-3">
                  <AvField
                    name="names"
                    label="Nombres"
                    type="text"
                    errorMessage="Campo inválido"
                    validate={{
                      required: { value: true },
                    }}
                    value={driverDetail.names || ""}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="col-6">
                <div className="mb-3">
                  <AvField
                    name="first_surname"
                    label="Apellido Paterno"
                    type="text"
                    errorMessage="Campo inválido"
                    validate={{
                      required: { value: true },
                    }}
                    value={driverDetail.first_surname || ""}
                  />
                </div>
              </Col>
              <Col className="col-6">
                <div className="mb-3">
                  <AvField
                    name="second_surname"
                    label="Apellido Materno"
                    type="text"
                    errorMessage="Campo inválido"
                    validate={{
                      required: { value: true },
                    }}
                    value={driverDetail.second_surname || ""}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="col-6">
                <div className="mb-3">
                  <AvField
                    name="email"
                    label="Email"
                    type="email"
                    errorMessage="Campo inválido"
                    validate={{
                      required: { value: true },
                    }}
                    value={driverDetail.email || ""}
                  />
                </div>
              </Col>
              <Col className="col-6">
                <div className="mb-3">
                  <AvField
                    name="phone"
                    label="Teléfono"
                    type="text"
                    errorMessage="Campo inválido"
                    validate={{
                      required: { value: true },
                    }}
                    value={driverDetail.phone || "1"}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-rounded btn-danger w-lg"
                    onClick={() => toggle()}
                  >
                    Cancelar
                  </button>
                  <button
                    type="submit"
                    className="btn btn-rounded btn-success w-lg save-user gap-2"
                  >
                    Guardar
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    </div>
  )
}

DriverEditModal.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  driverDetail: PropTypes.object,
  handleSubmit: PropTypes.func,
  errors: PropTypes.object,
}

export default DriverEditModal
