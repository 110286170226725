import React from "react"
import PropTypes from "prop-types"

import { Row, Col, Modal, ModalHeader, ModalBody, Button } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"

import { useDispatch } from "react-redux"
import { updateClient } from "store/actions"

const UserSuccessModal = ({ modal, toggle, id }) => {
  const dispatch = useDispatch()

  const handleOnClick = async (id, estado) => {
    dispatch(updateClient(id, { user: { general_information_status: estado } }))

    toggle()
  }

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader
        toggle={toggle}
        tag="h4"
        className="d-flex justify-content-center"
      >
        Estado de Usuario
      </ModalHeader>
      <ModalBody>
        <AvForm>
          <Row className="d-flex justify-content-center mb-3">
            Desea cambiar el estado de los datos del usuario a Validado
          </Row>

          <Row className="mt-5 ">
            <Col></Col>
            <Col sm="4" className="d-flex justify-content-end mb-3">
              <Button
                className="btn-round-card h-30 w-100"
                color="white"
                onClick={toggle}
              >
                Cancelar
              </Button>
            </Col>
            <Col sm="4" className="d-flex justify-content-end mb-3">
              <Button
                className="btn-round-card h-30 w-100"
                color="primary"
                onClick={() => handleOnClick(id, "validated")}
              >
                Aceptar
              </Button>
            </Col>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

UserSuccessModal.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  id: PropTypes.string,
}

export default UserSuccessModal
