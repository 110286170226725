import React, { useState, useRef } from "react"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"

import ClientEdit from "./ClientEdit"
import UserEditModal from "components/UserInfo/UserEditModal"

import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import UserAvatar2 from "components/UserAvatar/UserAvatar2"
import { CardBody, Col, Row, Badge, UncontrolledTooltip } from "reactstrap"
import { useTranslation } from "react-i18next"

import moment from "moment"

import { useDispatch } from "react-redux"
import { updateClient } from "store/actions"

const ClientTableList = ({ userList, errors }) => {
  const dispatch = useDispatch()
  const { t, i18n } = useTranslation()
  const node = useRef()

  // ------------------------------------------------------------------------
  // State
  // ------------------------------------------------------------------------

  const [modal, setModal] = useState(false)
  const [currentClient, setCurrentClient] = useState({})
  const [isEdit, setIsEdit] = useState(false)

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const selectRow = {
    mode: "checkbox",
  }

  const pageOptions = {
    sizePerPage: 10,
    totalSize: userList?.length, // replace later with size(orders),
    custom: true,
  }

  const UserOrderColumns = toggleModal => [
    {
      dataField: "document_status",
      text: "Status",
      sort: true,
      //eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        row.is_active === true ?
          <Link to={`/client-profile/${row.id}`}>
            <Badge
              className={"font-size-12 badge-soft-" + row.document_status}
              color={row.document_status}
              pill
            >
              {t(row.document_status)}
            </Badge>
          </Link>
        :
          <Badge
            className={"font-size-12 badge-soft-" + row.document_status}
            color={row.document_status}
            pill
          >
            {t(row.document_status)}
          </Badge>
      ),
    },
    {
      dataField: "inserted_at",
      text: "Fecha de Ingreso",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        row.is_active === true ?
          <Link to={`/client-profile/${row.id}`} className="text-body fw-bold">
            {handleValidDate(row.inserted_at)}
          </Link>
        :
          <p className="text-body fw-bold">{handleValidDate(row.inserted_at)}</p>
      ),
    },
    {
      dataField: "names",
      text: "Nombre",
      sort: true,
      formatter: (cellContent, row) => (
        row.is_active === true ?
          <Link to={`/client-profile/${row.id}`} className="text-body fw-bold">
            <UserAvatar2
              names={row.names}
              first_surname={row.first_surname}
              second_surname={row.second_surname}
              email={row.email}
              avatar={row.avatar}
            />
          </Link>
        :
          <p className="text-body fw-bold">
            <UserAvatar2
              names={row.names}
              first_surname={row.first_surname}
              second_surname={row.second_surname}
              email={row.email}
              avatar={row.avatar}

            />
          </p>
      ),
    },
    {
      dataField: "phone",
      text: "Telefono",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        row.is_active === true ?
          <Link to={`/client-profile/${row.id}`} className="text-body fw-bold">
            +55 {row.phone}
          </Link>
        :
          <p className="text-body fw-bold">+55 {row.phone}</p>
      ),
    },
    {
      dataField: "sub_type",
      text: "Tipo de Persona",
      sort: true,
      formatter: (cellContent, row) => (
        row.is_active === true ?
          <Link to={`/client-profile/${row.id}`} className="text-body fw-bold">
            {t(row.sub_type)}
          </Link>
        :
          <p className="text-body fw-bold">{t(row.sub_type)}</p>
      ),
    },
    // ------------------------------------------------------------------------
    // Actions
    // ------------------------------------------------------------------------
    {
      dataField: "view",
      isDummyField: true,
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="form-check form-switch form-switch-lg mb-3">
          {/* Active */}
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitch1"
            checked={ row.is_active }
            onChange={() => {
              switchActive(row.id, row.is_active)
            }}
          />
          <label
            className="form-check-label"
            htmlFor="customSwitchsizelg"
          ></label>
          {/* Edit */}
          <Link
            to="#"
            className="text-secondary"
            onClick={() => handleEdit(row)}
          >
            <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
            <UncontrolledTooltip placement="top" target="edittooltip">
              Edit
            </UncontrolledTooltip>
          </Link>
        </div>
      ),
    },
  ]

  // ------------------------------------------------------------------------
  // Functions
  // ------------------------------------------------------------------------

  const toggle = () => {
    setModal(!modal)
  }

  const handleValidDate = date => {
    return moment(new Date(date)).format("DD MMM Y")
  }

  const onPaginationPageChange = page => {
    if (node?.current?.props?.pagination?.options) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  const switchActive = (id, value) => {
    dispatch(updateClient(id, {user: {is_active: !value}}))
  }

  const handleEdit = client => {
    if (client.is_active === true) {
      setCurrentClient(client)
      setIsEdit(true)
      toggle()
    }
  }

  const handleSubmitForm = (e, data) => {
    if(modal) {
      dispatch(updateClient(currentClient.id, {user: data}))
    }

    if (errors === null) {
      toggle()
    }
  }

  return (
    <CardBody>
      <PaginationProvider
        pagination={paginationFactory(pageOptions)}
        keyField="id"
        columns={UserOrderColumns(toggle)}
        data={userList}
      >
        {({ paginationProps, paginationTableProps }) => (
          <ToolkitProvider
            keyField="id"
            data={userList}
            columns={UserOrderColumns(toggle)}
            bootstrap4
            search
          >
            {toolkitProps => (
              <React.Fragment>
                <Row>
                  <Col xl="12">
                    <div className="table-responsive">
                      <BootstrapTable
                        keyField="id"
                        responsive
                        bordered={false}
                        striped={false}
                        defaultSorted={defaultSorted}
                        selectRow={selectRow}
                        classes={"table align-middle table-nowrap table-check"}
                        headerWrapperClasses={"table-light"}
                        {...toolkitProps.baseProps}
                        {...paginationTableProps}
                        ref={node}
                      />
                    </div>
                  </Col>
                </Row>
                <Row className="align-items-md-center mt-30">
                  <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                    <PaginationListStandalone {...paginationProps} />
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </ToolkitProvider>
        )}
      </PaginationProvider>

      <UserEditModal
        modal={modal}
        toggle={toggle}
        userDetail={currentClient}
        handleSubmit={ handleSubmitForm }
      />
    </CardBody>
  )
}

ClientTableList.propTypes = {
  userList: PropTypes.array,
  errors: PropTypes.array,
}

export default withRouter(ClientTableList)
