import { takeEvery, put, call } from "redux-saga/effects"

import {
  GET_TRIPS,
  GET_TRIP,
  PUT_TRIP_PAYMENT,
  SEARCH_TRIP,
  FILTER_TRIP,
} from "./actionTypes"

import {
  getTripsSuccess,
  getTripsFail,

  getTripSuccess,
  getTripFail,

  putTripPaymentSuccess,
  putTripPaymentFail,

  searchTripSuccess,
  searchTripFail,

  filterTripSuccess,
  filterTripFail,
} from "./actions"

import {
  listTrips,
  getTrip,
  updateTripPay,
  searchTrips,
  filterTrips,
} from "../../helpers/backend_helper"

function* onGetTrips({ filters }) {
  try {
    const response = yield call(listTrips, filters)

    yield put(getTripsSuccess(response))
  } catch (error) {
    yield put(getTripsFail(error))
  }
}

// ----------------------------------------------------------------------------

function* onGetTrip({ id }) {
  try {
    const response = yield call(getTrip, id)

    yield put(getTripSuccess(response))
  } catch (error) {
    yield put(getTripFail(error))
  }
}

// ----------------------------------------------------------------------------

function* onPutTripPayment({ id, payment, validation }) {
  try {
    const response = yield call(updateTripPay, id, payment, validation)

    yield put(putTripPaymentSuccess(response))
  } catch (error) {
    yield put(putTripPaymentFail(error))
  }
}

// ----------------------------------------------------------------------------

function* onSearchTrips({ filters }) {
  try {
    const response = yield call(searchTrips, filters)

    yield put(searchTripSuccess(response))
  } catch (error) {
    yield put(searchTripFail(error))
  }
}

// ----------------------------------------------------------------------------

function* onFilterTrips({ filter }) {
  try {
    const response = yield call(filterTrips, filter)

    yield put(filterTripSuccess(response))
  } catch (error) {
    yield put(filterTripFail(error))
  }
}

function* TripsSaga() {
  yield takeEvery(GET_TRIPS, onGetTrips)
  yield takeEvery(GET_TRIP, onGetTrip)
  yield takeEvery(PUT_TRIP_PAYMENT, onPutTripPayment)
  yield takeEvery(SEARCH_TRIP, onSearchTrips)
  yield takeEvery(FILTER_TRIP, onFilterTrips)
}

export default TripsSaga
