import { takeEvery, put, call } from "redux-saga/effects"

import {
  GET_ADMINS,
  POST_ADMIN,
  PUT_ADMIN,
  DELETE_ADMIN,
  SEARCH_ADMIN,
} from "./actionTypes"

import {
  getAdminsSuccess,
  getAdminsFail,

  createAdminSuccess,
  createAdminFail,

  updateAdminSuccess,
  updateAdminFail,

  deleteAdminSuccess,
  deleteAdminFail,

  searchAdminSuccess,
  searchAdminFail,
} from "./actions"

import {
  listAdmins,
  newAdmin,
  updateAdmin,
  deleteAdmin,
  searchUsers,
} from "../../helpers/backend_helper"


function* onGetAdmins() {
  try {
    const response = yield call(listAdmins)

    yield put(getAdminsSuccess(response))
  } catch (error) {
    yield put(getAdminsFail(error))
  }
}

// ----------------------------------------------------------------------------

function* onCreateAdmin({ data }) {
  try {
    const response = yield call(newAdmin, data)

    yield put(createAdminSuccess(response))
  } catch (error) {
    yield put(createAdminFail(error))
  }
}

// ----------------------------------------------------------------------------

function* onUpdateAdmin({ id, data }) {
  try {
    const response = yield call(updateAdmin, id, data)

    yield put(updateAdminSuccess(response))
  } catch (error) {
    yield put(updateAdminFail(error))
  }
}

// ----------------------------------------------------------------------------

function* onDeleteAdmin({ id }) {
  try {
    const response = yield call(deleteAdmin, id)

    yield put(deleteAdminSuccess(response))
  } catch (error) {
    yield put(deleteAdminFail(error))
  }
}

// ----------------------------------------------------------------------------

function* onSearchAdmins({ filter }) {
  try {
    const response = yield call(searchUsers, filter)

    yield put(searchAdminSuccess(response))
  } catch (error) {
    yield put(searchAdminFail(error))
  }
}

function* AnalystsSaga() {
  yield takeEvery(GET_ADMINS, onGetAdmins)
  yield takeEvery(POST_ADMIN, onCreateAdmin)
  yield takeEvery(PUT_ADMIN, onUpdateAdmin)
  yield takeEvery(DELETE_ADMIN, onDeleteAdmin)
  yield takeEvery(SEARCH_ADMIN, onSearchAdmins)
}

export default AnalystsSaga
