import {
  LIST_NOTIFICATIONS,
  LIST_NOTIFICATIONS_SUCCESS,
  LIST_NOTIFICATIONS_FAIL,

  REMOVE_NOTIFICATIONS,

  NEW_NOTIFICATION,
  NEW_NOTIFICATION_SUCCESS,
  NEW_NOTIFICATION_FAIL,
} from "./actionTypes"

export const listNotifications = data => ({
  type: LIST_NOTIFICATIONS,
  data
})

export const listNotificationsSuccess = data => ({
  type: LIST_NOTIFICATIONS_SUCCESS,
  payload: data,
})

export const listNotificationsFail = error => ({
  type: LIST_NOTIFICATIONS_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------

export const removeNotifications = chatId => ({
  type: REMOVE_NOTIFICATIONS,
  chatId
})

// ----------------------------------------------------------------------------
// ----------------------------------------------------------------------------

export const newNotification = data => ({
  type: NEW_NOTIFICATION,
  data
})

export const newNotificationSuccess = data => ({
  type: NEW_NOTIFICATION_SUCCESS,
  payload: data,
})

export const newNotificationFail = error => ({
  type: NEW_NOTIFICATION_FAIL,
  payload: error,
})
