import { takeEvery, put, call } from "redux-saga/effects"

import { LIST_NOTIFICATIONS } from "./actionTypes"

import {
  listNotificationsSuccess,
  listNotificationsFail,
} from "./actions"

import { listUnseenMessages } from "../../helpers/backend_helper"

function* onListUnseenMessages() {
  try {
    const response = yield call(listUnseenMessages)
    yield put(listNotificationsSuccess(response))
  } catch (error) {
    yield put(listNotificationsFail(error))
  }
}

function* notificationsSaga() {
  yield takeEvery(LIST_NOTIFICATIONS, onListUnseenMessages)
}

export default notificationsSaga
