import React from "react"

import ClientHeader from "./ClientHeader"

import UserInfo from "../../../../components/UserInfo"
import PropTypes from "prop-types"
import { Col } from "reactstrap"
import "./ClientMain.scss"
import DocumentSection from "./DocumentSection"

const ClientMain = ({ documents, userDetail, errors }) => {

  const userDetailFilter = {
    names: userDetail.names,
    first_surname: userDetail.first_surname,
    second_surname: userDetail.second_surname,
    email: userDetail.email,
    phone: userDetail.phone,
    rfc: userDetail.rfc,
    sub_type: userDetail.sub_type,
  }

  return (
    <Col size="69%" className="email-rightbar">
      <ClientHeader />

      <UserInfo
        userData={userDetailFilter}
        userDetail={userDetail}
        errors={errors}
      />

      <DocumentSection
        documents={documents}
      />
    </Col>
  )
}

ClientMain.propTypes = {
  documents: PropTypes.array,
  userDetail: PropTypes.object,
  errors: PropTypes.object,
}

export default ClientMain
