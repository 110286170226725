import React from "react"
import PropTypes from "prop-types"

import { Col, Row } from "reactstrap"
import { Link } from "react-scroll"

import * as moment from "moment"

import "../../../components/UserInfo/UserInfo.scss"

import { useTranslation } from "react-i18next"

const CardDataTravel = ({ travelDetail }) => {
  const { t } = useTranslation()

  const optionsMoney = { style: 'currency', currency: 'MXN' };
  const numFormat = new Intl.NumberFormat('es-MX', optionsMoney);

  const handleValidDate = date => {
    return moment(new Date(date)).format("DD MMM Y - HH:MM a")
  }

  return (
    <div
      className="user-info w-100 position-relative"
      style={{ padding: "73px 98px 36px 100px" }}
      id="travelDetails"
    >
      <div
        className="sub-container text-light w-25 position-absolute"
        style={{
          backgroundColor: "#FFA400",
          left: "0px",
          top: "24px",
          lineHeight: "33px",
          display: "flex",
          justifyContent: "center",
          fontSize: "16px",
          fontWeight: "700",
        }}
      >
        { t(travelDetail.status) }
      </div>

      <p
        className="font-size-16 position-absolute"
        style={{ right: "96px", top: "24px" }}
      >
        <strong>Folio:</strong> {travelDetail.id}
      </p>

      <Row>
        <Row style={{ marginBottom: "50px", marginTop: "45px" }}>
          <Col
            sm="6"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
              padding: 0,
              height: "36px",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                padding: "0 12px",
                margin: 0,
                fontWeight: 400,
              }}
            >
              Viaje normal{" "}
            </p>
            <p
              style={{
                fontSize: "16px",
                padding: "0 12px",
                margin: 0,
                fontWeight: 700,
              }}
            >
              {travelDetail.start_address?.city}-
            </p>
            <p
              style={{
                fontSize: "16px",
                padding: "0 12px",
                margin: 0,
                fontWeight: 700,
              }}
            >
              {travelDetail.end_address?.city}
            </p>
          </Col>
          <Col
            sm="6"
            style={{
              textAlign: "right",
              display: "flex",
              justifyContent: "flex-end",
              flexDirection: "column",
              height: "36px",
            }}
          >
            <p style={{ padding: 0, margin: "0 0 5px" }}>Monto a pagar</p>
            <p
              style={{
                padding: 0,
                margin: 0,
                fontSize: "34px",
                fontWeight: "500",
                lineHeight: "30px",
              }}
            >
              {numFormat.format(travelDetail?.budget)}{" "}
              <span
                style={{
                  fontSize: "24px",
                  fontWeight: "normal",
                  paddingLeft: "15px",
                }}
              >
                MXN
              </span>
            </p>
          </Col>
        </Row>
        <Col sm="6">
          <Row>
            <Col sm="2">
              <i className="mdi mdi-clock"></i>
            </Col>
            <Col sm="6">
              <Row>
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "30px",
                  }}
                >
                  Salida
                </p>
              </Row>
              <Row>
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: "12px",
                    fontWeight: "300",
                    lineHeight: "30px",
                  }}
                >
                  {handleValidDate(travelDetail.start_at)}
                </p>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginBottom: "50px", marginTop: "35px" }}>
            <Row style={{ fontWeight: "800" }}>Dirección de origen</Row>
            <p>
              <Row>
                {travelDetail.start_address?.street} Num.Ext{" "}
                {travelDetail.start_address?.ext_number}
              </Row>
              <Row>
                {travelDetail.start_address?.street} Num.Int{" "}
                {travelDetail.start_address?.int_number}
              </Row>
              <Row>
                {travelDetail.start_address?.neiborhood}
                {", "}
                {travelDetail.start_address?.state}
              </Row>
              <Row>C.P {travelDetail.start_address?.zip_code}</Row>
              <Row
                style={{
                  marginBottom: "3px",
                  marginTop: "6px",
                  fontWeight: "800",
                  fontSize: "14px",
                }}
              >
                Nota{" "}
              </Row>
              <Row>{travelDetail.start_address?.address_reference}</Row>
              <Row
                style={{
                  marginTop: "18px",
                }}
              >
                <Link to="#">Ver en mapa</Link>
              </Row>
            </p>
          </Row>
        </Col>
        <Col sm="6">
          <Row>
            <Col sm="2">
              <i className="mdi mdi-clock"></i>
            </Col>
            <Col sm="6">
              <Row>
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "30px",
                  }}
                >
                  Llegada Estimada
                </p>
              </Row>
              <Row>
                <p
                  style={{
                    padding: 0,
                    margin: 0,
                    fontSize: "12px",
                    fontWeight: "300",
                    lineHeight: "30px",
                  }}
                >
                  {handleValidDate(travelDetail.end_at)}
                </p>
              </Row>
            </Col>
          </Row>
          <Row style={{ marginBottom: "50px", marginTop: "35px" }}>
            <Row style={{ fontWeight: "800" }}>Dirección de destino</Row>
            <p>
              <Row>
                {travelDetail.end_address?.street} Num.Ext{" "}
                {travelDetail.end_address?.ext_number}
              </Row>
              <Row>
                {travelDetail.end_address?.street} Num.Int{" "}
                {travelDetail.end_address?.int_number}
              </Row>
              <Row>
                {travelDetail.end_address?.neiborhood}
                {", "}
                {travelDetail.end_address?.state}
              </Row>
              <Row>C.P {travelDetail.end_address?.zip_code}</Row>
              <Row
                style={{
                  marginBottom: "3px",
                  marginTop: "6px",
                  fontWeight: "800",
                  fontSize: "14px",
                }}
              >
                Nota{" "}
              </Row>
              <Row>{travelDetail.end_address?.address_reference}</Row>
              <Row
                style={{
                  marginTop: "18px",
                }}
              >
                <Link to="#">Ver en mapa</Link>
              </Row>
            </p>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

CardDataTravel.propTypes = {
  travelDetail: PropTypes.object,
}

export default CardDataTravel
