import {
  POST_NEW_CHAT,
  POST_NEW_CHAT_SUCCESS,
  POST_NEW_CHAT_FAIL,

  GET_CHATS,
  GET_CHATS_FAIL,
  GET_CHATS_SUCCESS,

  GET_MESSAGES,
  GET_MESSAGES_FAIL,
  GET_MESSAGES_SUCCESS,

  VIEWED_MESSAGES,

  POST_ADD_MESSAGE,
  POST_ADD_MESSAGE_FAIL,
  POST_ADD_MESSAGE_SUCCESS,

  PUT_UNVIEWED_MESSAGES,
  PUT_UNVIEWED_MESSAGES_SUCCESS,
  PUT_UNVIEWED_MESSAGES_FAIL,

  SEARCHER_CHATS,
  SET_CURRENT_CHAT,
} from "./actionTypes"


export const postNewChat = data => ({
  type: POST_NEW_CHAT,
  data
})

export const postNewChatSuccess = data => ({
  type: POST_NEW_CHAT_SUCCESS,
  payload: data,
})

export const postNewChatFail = error => ({
  type: POST_NEW_CHAT_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------

export const getChats = () => ({
  type: GET_CHATS,
})

export const getChatsSuccess = chats => ({
  type: GET_CHATS_SUCCESS,
  payload: chats,
})

export const getChatsFail = error => ({
  type: GET_CHATS_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------

export const getMessages = roomId => ({
  type: GET_MESSAGES,
  roomId,
})

export const getMessagesSuccess = messages => ({
  type: GET_MESSAGES_SUCCESS,
  payload: messages,
})

export const getMessagesFail = error => ({
  type: GET_MESSAGES_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------

export const viewedMessages = currentUserId => ({
  type: VIEWED_MESSAGES,
  currentUserId,
})

// ----------------------------------------------------------------------------

export const addMessage = message => ({
  type: POST_ADD_MESSAGE,
  message,
})

export const addMessageSuccess = message => ({
  type: POST_ADD_MESSAGE_SUCCESS,
  payload: message,
})

export const addMessageFail = error => ({
  type: POST_ADD_MESSAGE_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------

export const updateUnViewedMessages = (roomId) => ({
  type: PUT_UNVIEWED_MESSAGES,
  roomId
})

export const updateUnViewedMessagesSuccess = chats => ({
  type: PUT_UNVIEWED_MESSAGES_SUCCESS,
  payload: chats,
})

export const updateUnViewedMessagesFail = error => ({
  type: PUT_UNVIEWED_MESSAGES_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------

export const searcherChats = filter => ({
  type: SEARCHER_CHATS,
  filter,
})

// ----------------------------------------------------------------------------

export const setCurrentChatId = id => ({
  type: SET_CURRENT_CHAT,
  id,
})
