import React from "react"
import PropTypes from "prop-types"

import { Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"

import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"

const DocumentCardModal = ({modal, toggle, title, url, isPdf, altText = "", status, handleOnClickAlert, handleOnClickVerification}) => {
  const { t, i18n } = useTranslation()

  return (
    <Modal isOpen={modal} toggle={toggle} size={(isPdf ? "lg" : "md")}>
      <ModalHeader
        toggle={toggle}
        tag="h4"
        className="d-flex justify-content-center"
      >
        {t(title)}
      </ModalHeader>

      <ModalBody>
        <AvForm>
          <Row className="d-flex justify-content-center">
            { isPdf ? <iframe src={url} style={{ width: "800px", height: "900px"}}></iframe> : <img src={url} alt={altText} />}
          </Row>

          <Row className="mt-5 ">
            <Col></Col>
            <Col sm="5" className="d-flex justify-content-center mb-3">
              <Link
                to="#"
                className="text-empty"
                onClick={() => handleOnClickVerification()}
              >
                <i
                  className={
                    status === "irregularity"
                      ? "bx bxs-x-circle text-" + status
                      : "bx bx-x-circle"
                  }
                ></i>
              </Link>
            </Col>
            <Col sm="6" className="d-flex justify-content-center mb-3">
              <Link
                to="#"
                className="text-empty"
                onClick={() => handleOnClickAlert()}
              >
                <i
                  className={
                    status === "validated"
                      ? "bx bxs-check-circle text-" + status
                      : "bx bx-check-circle"
                  }
                ></i>
              </Link>
            </Col>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

DocumentCardModal.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  title: PropTypes.string,
  url: PropTypes.string,
  isPdf: PropTypes.bool,
  altText: PropTypes.string,
  status: PropTypes.string,
  handleOnClickAlert: PropTypes.func,
  handleOnClickVerification: PropTypes.func,
}

export default DocumentCardModal
