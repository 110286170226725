import React from "react"

import { Col, Row } from "reactstrap"

import TravelHeader from "./TravelHeader"
import PropTypes from "prop-types"

import CardDataTravel from "./CardDataTravel"
import CardProgressTravel from "./CardProgressTravel"
import DocumentSection from "./DocumentSection"
import TravelHistory from "./TravelTable"
import TravelOffers from "./TableOffers"
import ReviewsTravel from "./ReviewsTravel"
import CompletionEvidenceTravel from "./CompletionEvidenceTravel"

import "./Travel.scss"

const TravelMain = ({ travelDetail, getTravelDetail }) => {
  const { id, payments } = travelDetail


  return (
    <Col size="69%" className="email-rightbar">
      <TravelHeader travelDetail={travelDetail} />

      <CardDataTravel travelDetail={travelDetail} />

      <Row className="mt-5">
        <Col md="1">
          <i className="mdi mdi-view-list"></i>
        </Col>
        <Col md="9">
          <span className="label-nav" style={{ fontWeight: "800" }}>
            Historico de ofertas
          </span>
        </Col>
      </Row>
      <TravelOffers travelDetail={travelDetail} />

      <Row className="mt-5">
        <Col md="1">
          <i className="bx bx-credit-card"></i>
        </Col>
        <Col md="9" id="payment">
          <span className="label-nav" style={{ fontWeight: "800" }}>
            Pagos
          </span>
        </Col>
      </Row>
      <DocumentSection
        trip_id={id}
        payments={payments}
        getTravelDetail={getTravelDetail}
      />

      <Row className="mt-5">
        <Col md="1">
          <i className="bx bxs-map-pin"></i>
        </Col>
        <Col md="9">
          <span className="label-nav" style={{ fontWeight: "800" }}>
            Progreso del viaje
          </span>
        </Col>
      </Row>
      <CardProgressTravel travelDetail={travelDetail} />

      <Row className="mt-5">
        <Col md="1">
          <i className="mdi mdi-view-list"></i>
        </Col>
        <Col md="9">
          <span className="label-nav" style={{ fontWeight: "800" }}>
            Listado de actividades
          </span>
        </Col>
      </Row>
      <div className="user-info w-100 position-relative">
        <Row>
          <TravelHistory travelDetail={travelDetail} />
        </Row>
      </div>

      <Row className="mt-5">
        <Col md="1">
          <i className="bx bxs-map"></i>
        </Col>
        <Col md="9">
          <span className="label-nav" style={{ fontWeight: "800" }}>
            Viaje finalizado
          </span>
        </Col>
      </Row>
      <CompletionEvidenceTravel
        photo={travelDetail.client_finish_accepted_photo}
      />

      <Row className="mt-5">
        <Col md="1">
          <i className="bx bxs-star"></i>
        </Col>
        <Col md="9">
          <span className="label-nav" style={{ fontWeight: "800" }}>
            Reviews
          </span>
        </Col>
      </Row>
      <ReviewsTravel
        review={travelDetail.review}
        driver={travelDetail.driver}
        client={travelDetail.client}
      />
    </Col>
  )
}
TravelMain.propTypes = {
  travelDetail: PropTypes.object,
  getTravelDetail: PropTypes.func,
}
export default TravelMain
