import React from "react"
import PropTypes from "prop-types"

import { Row, Col } from "reactstrap"
import Rating from "react-rating"

import images from "assets/images"

const UserAvatarReview = ({ user, stars }) => {
  return (
    <Row>
      <Col sm="2">
        <div className="align-self-justify me-4">
          <img
            src={user.avatar || images.avatar1}
            className="avatar-xs rounded-circle"
            alt=""
          />
        </div>
      </Col>
      <Col sm="9">
        <Row>
          <Col sm="10">
            {user.names + " " + user.first_surname + " " + user.second_surname}
          </Col>
        </Row>
        <Row>
          <Col sm="10">
            <Rating
              emptySymbol="mdi mdi-star-outline fa-2x"
              fullSymbol="mdi mdi-star-circle fa-2x text-warning"
              readonly={true}
              initialRating={stars}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

UserAvatarReview.propTypes = {
  user: PropTypes.object,
  stars: PropTypes.number,
}

export default UserAvatarReview
