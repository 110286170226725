import React, { useEffect, useState } from "react"

import { useParams } from "react-router-dom"
import { Container, Row } from "reactstrap"

import TravelMain from "../TravelMain"
import TravelSideBar from "./TravelSidebar"

import { getTrip } from "helpers/backend_helper"

const TravelView = () => {
  const { id } = useParams()
  const [travelDetail, setTravelDetail] = useState({})

  const getTravelDetail = async () => {
    const travelDetail = await getTrip(id)
    setTravelDetail(travelDetail.data)
  }

  useEffect(() => {
    getTravelDetail()
  }, [])

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <TravelSideBar
            travelDetail={travelDetail}
            getTravelDetail={getTravelDetail}
          />
          <TravelMain
            travelDetail={travelDetail}
            getTravelDetail={getTravelDetail}
          />
        </Row>
      </Container>
    </div>
  )
}

export default TravelView
