import React from "react"
import PropTypes from "prop-types"

import { Row } from "reactstrap"

import DocumentCardPay from "components/DocumentCardPay"

const DocumentSection = ({ trip_id, payments, getTravelDetail }) => {
  let num_payments = 0

  if (payments) {
    num_payments = payments.length
  }


  const handleTitle = (status, number) => {    
    if (status == "validated") {
      if (number == 1) {
        return "Primer Pago aprobado"
      } else {
        return "Segundo Pago aprobad"
      }
    } else if (status == "irregularity") {
      
      if (number == 1) {
        return "Primer Pago rechazado"
      } else {
        return "Segundo Pago rechazado"
      } 

    } else if (status == "sended") {
      if (number == 1) {
        return "Primer Pago por validar"
      } else {
        return "Segundo Pago por validar"
      } 
    }
  }

  return (
    <div className="document-section user-info w-100">
      {
        num_payments > 0 ?
          payments?.map(({ amount, inserted_at, status, url, id, number}, index) => (
            <div className="document" key={index}>
              <DocumentCardPay
                title={handleTitle(status, number)}
                imgUrl={url}
                id={id}
                trip_id={trip_id}
                status={status}
                getTravelDetail={getTravelDetail}
                number={number}
              />
            </div>
          ))
        :
        <Row className="mt-5" style={{ fontWeight: "600", color: "#757575"}}>
          Aún no sea ha realizado ningún pago
        </Row>
      }
    </div>
  )
}

DocumentSection.propTypes = {
  trip_id: PropTypes.string,
  payments: PropTypes.array,
  getTravelDetail: PropTypes.func,
}

export default DocumentSection
