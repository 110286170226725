import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"

import { withRouter, Link } from "react-router-dom"

import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider from "react-bootstrap-table2-toolkit"
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "components/Common/BreadcrumbSearch"

import { useTranslation } from "react-i18next"
import * as moment from "moment"

import TravelEdit from "../TravelList/TravelEdit"
import TravelHeaderList from "./TravelHeaderList"

import { getTrips, searchTrip } from "store/actions"

const TravelList = props => {
  const dispatch = useDispatch()

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const selectRow = {
    mode: "checkbox",
  }

  const { trips, errors } = useSelector(state => ({
    trips: state.trips.trips,
    errors: state.analysts.errors
  }))

  useEffect(() => {
    dispatch(getTrips(""))
  }, [getTrips])


  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: trips?.length, // replace later with size(orders),
    custom: true,
  }

  const { t, i18n } = useTranslation()

  const options = [
    {value: "address_start", name: "Origen"},
    {value: "address_end", name: "Destino"},
    {value: "zip_code", name: "Código postal"},
    {value: "driver_names", name: "Transportista (nombre)"},
    {value: "driver_email", name: "Transportista (email)"},
    {value: "client_names", name: "Cliente (nombre)"},
    {value: "client_email", name: "Cliente (email)"},
    {value: "uid", name: "UID"},
  ]

  // ------------------------------------------------------------------------
  // Functions
  // ------------------------------------------------------------------------

  const handleSearch = (searcher, searcherType) => {
    dispatch(searchTrip(`${searcherType}=${searcher}`))
  }

  const toggle = () => {
    setModal(!modal)
  }

  const node = useRef()
  const onPaginationPageChange = page => {
    if (node?.current?.props?.pagination?.options) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  const handleValidOrderSubmit = (e, values) => {
    toggle()
  }

  const handleValidDate = date => moment(new Date(date)).format("DD MMM Y")

  const handleOrderClick = order => {
    setOrderList({
      id: order.id,
      orderId: order.orderId,
      billingName: order.billingName,
      orderdate: order.orderdate,
      total: order.total,
      paymentStatus: order.paymentStatus,
      paymentMethod: order.paymentMethod,
      badgeclass: order.badgeclass,
    })

    setIsEdit(true)
    toggle()
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const TripOrderColumns = toggleModal => [
    {
      dataField: "status",
      text: "Status",
      sort: true,
      //eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Link to={`/travel-detail/${row.id}`}>
          <Badge
            className={"font-size-12 badge-soft2-" + row.status}
            // color={row.status}
            pill
          >
            {t(row.status)}
          </Badge>
        </Link>
      ),
    },
    {
      dataField: "client.names",
      text: "Cliente",
      sort: true,
      formatter: (cellContent, row) => (
        <Link to={`/travel-detail/${row.id}`} className="text-body fw-bold">
          <Row>
            {t(row.client?.names)}{" "}
            {t(row.client?.first_surname)}{" "}{t(row.client?.first_surname)}
          </Row>
          <Row>{t(row.client?.email)}</Row>
        </Link>
      ),
    },
    {
      dataField: "driver.names",
      text: "Transportista",
      sort: true,
      formatter: (cellContent, row) => (
        <Link to={`/travel-detail/${row.id}`} className="text-body fw-bold">
          <Row>
            {row.driver?.names}{" "}{t(row.driver?.first_surname)}{" "}
            {t(row.driver?.second_surname)}
          </Row>
          <Row>{row.driver?.email}</Row>
        </Link>
      ),
    },
    {
      dataField: "start_address.city",
      text: "Origen",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Link to={`/travel-detail/${row.id}`} className="text-body fw-bold">
          <Row>{row.start_address.city}</Row>
          <Row>
            {row.start_address.street}
          </Row>
        </Link>
      ),
    },
    {
      dataField: "end_address.city",
      text: "Destino",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Link to={`/travel-detail/${row.id}`} className="text-body fw-bold">
          <Row>{row.end_address.city}</Row>
          <Row>
            {row.end_address.street}
          </Row>
        </Link>
      ),
    },
    {
      dataField: "start_at",
      text: "Inicio de viaje",
      sort: true,
      formatter: (cellContent, row) => (
        <Link to={`/travel-detail/${row.id}`} className="text-body fw-bold">
          {handleValidDate(row.start_at)}
        </Link>
      ),
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Lista Viajes | Infast</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Viajes"
            breadcrumbItem="Todos los Viajes"
            options={ options }
            default={ "address_start" }
            handleSearch={ handleSearch }
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={TripOrderColumns(toggle)}
                    data={trips}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={trips}
                        columns={TripOrderColumns(toggle)}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <TravelHeaderList trips={trips} />

                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    ref={node}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <TravelEdit
        modal={modal}
        toggle={toggle}
        isEdit={isEdit}
        handleValidOrderSubmit={handleValidOrderSubmit}
      />
    </React.Fragment>
  )
}

TravelList.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
}

export default withRouter(TravelList)
