export const LOGIN_USER = "LOGIN_USER"
export const LOGIN_SUCCESS = "LOGIN_SUCCESS"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"


export const FIREBASE_TOKEN = "FIREBASE_TOKEN"
export const FIREBASE_TOKEN_SUCCESS = "FIREBASE_TOKEN_SUCCESS"

export const API_ERROR = "LOGIN_API_ERROR"
