import {
  GET_DOCUMENTS,
  GET_DOCUMENTS_FAIL,
  GET_DOCUMENTS_SUCCESS,

  PUT_DOCUMENT,
  PUT_DOCUMENT_FAIL,
  PUT_DOCUMENT_SUCCESS,

  SET_ERROR,
} from "./actionTypes"


// ----------------------------------------------------------------------------
// List documents
// ----------------------------------------------------------------------------

export const getDocuments = (id) => ({
  type: GET_DOCUMENTS,
  id
})

export const getDocumentsSuccess = drivers => ({
  type: GET_DOCUMENTS_SUCCESS,
  payload: drivers,
})

export const getDocumentsFail = error => ({
  type: GET_DOCUMENTS_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------
// Update document
// ----------------------------------------------------------------------------

export const updateDocument = (id, data) => ({
  type: PUT_DOCUMENT,
  id,
  data,
})

export const updateDocumentSuccess = data => ({
  type: PUT_DOCUMENT_SUCCESS,
  payload: data,
})

export const updateDocumentFail = error => ({
  type: PUT_DOCUMENT_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------

export const setError = error => ({
  type: SET_ERROR,
  payload: error,
})