export const GET_VEHICLES = "GET_VEHICLES"
export const GET_VEHICLES_SUCCESS = "GET_VEHICLES_SUCCESS"
export const GET_VEHICLES_FAIL = "GET_VEHICLES_FAIL"

export const GET_VEHICLE_TYPES = "GET_VEHICLE_TYPES"
export const GET_VEHICLE_TYPES_SUCCESS = "GET_VEHICLE_TYPES_SUCCESS"
export const GET_VEHICLE_TYPES_FAIL = "GET_VEHICLE_TYPES_FAIL"

export const POST_VEHICLE = "POST_VEHICLE"
export const POST_VEHICLE_SUCCESS = "POST_VEHICLE_SUCCESS"
export const POST_VEHICLE_FAIL = "POST_VEHICLE_FAIL"

export const PUT_VEHICLE = "PUT_VEHICLE"
export const PUT_VEHICLE_SUCCESS = "PUT_VEHICLE_SUCCESS"
export const PUT_VEHICLE_FAIL = "PUT_VEHICLE_FAIL"

export const DELETE_VEHICLE = "DELETE_VEHICLE"
export const DELETE_VEHICLE_SUCCESS = "DELETE_VEHICLE_SUCCESS"
export const DELETE_VEHICLE_FAIL = "DELETE_VEHICLE_FAIL"

export const SET_ERROR = "SET_ERROR"

export const SEARCH_VEHICLE = "SEARCH_VEHICLE"
export const SEARCH_VEHICLE_SUCCESS = "SEARCH_VEHICLE_SUCCESS"
export const SEARCH_VEHICLE_FAIL = "SEARCH_VEHICLE_FAIL"
