import React, { useState } from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"

import { useTranslation } from "react-i18next"

const Breadcrumb = props => {
  const { t } = useTranslation()

  const [searcher, setSearcher] = useState("")
  const [searcherType, setSearcherType] = useState(props.default)

  // --------------------------------------------------------------------------
  // Functions
  // --------------------------------------------------------------------------

  const onKeyPressSearcher = (e) => {
    const { key, value } = e

    if (key === "Enter") {
      props.handleSearch(searcher, searcherType)
    }
  }

  const onChangeSelect = (e) => {
    setSearcherType(e.target.value)
  }

  return (
    <Row>
      <Col className="col-6">
        <div className="justify-content-between">
          <h4 className="mb-0 font-size-18 breadcrumb-title">{props.breadcrumbItem}</h4>
        </div>
      </Col>
      <Col className="col-6 bg-primar">
        <div className="searcher">
          <div className="position-relative">
            <input
              type="text"
              className="form-control"
              style={{minWidth: "210px", width: "auto"}}
              onKeyPress={ onKeyPressSearcher }
              onChange={e => setSearcher(e.target.value)}
              placeholder={t("Search") + "..."}
            />
            <span className="bx bx-search-alt" />

            <select defaultValue={ props.default } onChange={ onChangeSelect }>
              {props.options.map((item, index) => {
                return <option key={index} value={item.value}>{item.name}</option>
              })}
            </select>
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
  default: PropTypes.string,
  options: PropTypes.array,
  handleSearch: PropTypes.func,
}

export default Breadcrumb
