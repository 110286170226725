import React from "react"
import PropTypes from "prop-types"

import { Card, Table } from "reactstrap"

import TravelOffersList from "./TravelOffersList"

const TravelOffers = ({ travelDetail }) => {
  const { offers } = travelDetail

  return (
    <Card id="history">
      <Table>
        <thead>
          <tr>
            <th>Folio </th>
            <th>Registro de oferta </th>
            <th>Nombre </th>
            <th>Cantidad </th>
            <th>Mejor oferta</th>
          </tr>
        </thead>
        {offers?.map(({ amount, inserted_at, user, is_best }, index) => (
          <TravelOffersList
            key={index}
            index={index}
            amount={((Number(amount) *0.16)+Number(amount))}
            inserted_at={inserted_at}
            user={user}
            is_best={is_best}
          />
        ))}
      </Table>
    </Card>
  )
}

TravelOffers.propTypes = {
  travelDetail: PropTypes.object,
}

export default TravelOffers
