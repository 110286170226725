import {
  GET_ADMINS,
  GET_ADMINS_FAIL,
  GET_ADMINS_SUCCESS,

  POST_ADMIN,
  POST_ADMIN_FAIL,
  POST_ADMIN_SUCCESS,

  PUT_ADMIN,
  PUT_ADMIN_FAIL,
  PUT_ADMIN_SUCCESS,

  DELETE_ADMIN,
  DELETE_ADMIN_FAIL,
  DELETE_ADMIN_SUCCESS,

  SET_ERROR,

  SEARCH_ADMIN,
  SEARCH_ADMIN_FAIL,
  SEARCH_ADMIN_SUCCESS,
} from "./actionTypes"


// ----------------------------------------------------------------------------
// List admins
// ----------------------------------------------------------------------------

export const getAdmins = (filter) => ({
  type: GET_ADMINS,
  filter
})

export const getAdminsSuccess = admins => ({
  type: GET_ADMINS_SUCCESS,
  payload: admins,
})

export const getAdminsFail = error => ({
  type: GET_ADMINS_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------
// New Admin
// ----------------------------------------------------------------------------

export const createAdmin = data => ({
  type: POST_ADMIN,
  data,
})

export const createAdminSuccess = data => ({
  type: POST_ADMIN_SUCCESS,
  payload: data,
})

export const createAdminFail = error => ({
  type: POST_ADMIN_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------
// Update Admin
// ----------------------------------------------------------------------------

export const updateAdmin = (id, data) => ({
  type: PUT_ADMIN,
  id,
  data,
})

export const updateAdminSuccess = data => ({
  type: PUT_ADMIN_SUCCESS,
  payload: data,
})

export const updateAdminFail = error => ({
  type: PUT_ADMIN_FAIL,
  payload: error,
})


// ----------------------------------------------------------------------------
// Delete Admin
// ----------------------------------------------------------------------------

export const deleteAdmin = id => ({
  type: DELETE_ADMIN,
  id,
})

export const deleteAdminSuccess = data => ({
  type: DELETE_ADMIN_SUCCESS,
  payload: data,
})

export const deleteAdminFail = error => ({
  type: DELETE_ADMIN_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------

export const setError = error => ({
  type: SET_ERROR,
  payload: error,
})

// ----------------------------------------------------------------------------
// Admins Search
// ----------------------------------------------------------------------------

export const searchAdmin = filter => ({
  type: SEARCH_ADMIN,
  filter,
})

export const searchAdminSuccess = admins => ({
  type: SEARCH_ADMIN_SUCCESS,
  payload: admins,
})

export const searchAdminFail = error => ({
  type: SEARCH_ADMIN_FAIL,
  payload: error,
})
