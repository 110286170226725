import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import MetaTags from "react-meta-tags"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"

import ToolkitProvider from "react-bootstrap-table2-toolkit"


//Import Breadcrumb
import Breadcrumbs from "components/Common/BreadcrumbSearch"
import { getAdmins, createAdmin, updateAdmin, deleteAdmin, searchAdmin, setError } from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import TableHeader from "./tableHeader"
import ModalAdmin from "./modal"

import UserAvatar2 from "components/UserAvatar/UserAvatar2"

import moment from "moment"


const Analysts = props => {
  const dispatch = useDispatch()

  // ------------------------------------------------------------------------
  // State
  // ------------------------------------------------------------------------

  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const [currentUser, setUser] = useState(authUser.user)

  const [currentAdmin, setCurrentAdmin] = useState({})
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const options = [
    {value: "names", name: "Nombres"},
    {value: "first_surname", name: "Apellido paterno"},
    {value: "second_surname", name: "Apellido materno"},
    {value: "full_name", name: "Nombre completo"},
    {value: "email", name: "Email"},
    {value: "phone", name: "Teléfono"},
  ]

  const { admins, errors } = useSelector(state => ({
    admins: state.analysts.admins,
    errors: state.analysts.errors
  }))

  useEffect(() => {
    dispatch(getAdmins(""))
  }, [getAdmins])

  // ------------------------------------------------------------------------
  // Setup Base table
  // ------------------------------------------------------------------------

  const pageOptions = {
    sizePerPage: 10,
    totalSize: admins?.length,
    custom: true,
  }

  const selectRow = { mode: "checkbox" }
  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const tableColumns = toggleModal => [
    {
      dataField: "inserted_at",
      text: "Fecha de ingreso",
      sort: true,
      formatter: (cellContent, admin) => (
        <div className="text-body fw-bold">{ moment(new Date(admin.inserted_at)).format("DD MMM Y") }</div>
      ),
    },
    {
      dataField: "names",
      text: "Nombre",
      sort: true,
      formatter: (cellContent, admin) => (
        <Link to={`/client-profile/${admin.id}`} className="text-body fw-bold">
          <UserAvatar2
            avatar={admin.avatar}
            names={admin.names}
            first_surname={admin.first_surname}
            second_surname={admin.second_surname}
            email={admin.email}
          />
        </Link>
      ),
    },
    {
      dataField: "phone",
      text: "Teléfono",
      sort: true,
      formatter: (cellContent, admin) => (
        <div>+{ admin.phone }</div>
      ),
    },
    // ------------------------------------------------------------------------
    // Actions
    // ------------------------------------------------------------------------
    {
      dataField: "view",
      isDummyField: true,
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, admin) => (
        <div className="form-check form-switch form-switch-lg mb-3">
          {/* Active */}
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitch1"
            checked={ admin.is_active }
            disabled={ currentUser.id === admin.id ? true : false }
            onChange={() => {
              switchActive(admin.id, admin.is_active)
            }}
          />
          <label
            className="form-check-label"
            htmlFor="customSwitchsizelg"
          ></label>
          {/* Edit */}
          <Link
            to="#"
            className="text-secondary"
            onClick={() => handleEdit(admin)}
          >
            <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
            <UncontrolledTooltip placement="top" target="edittooltip">
              Edit
            </UncontrolledTooltip>
          </Link>
          {/* Delete */}
          {/* { currentUser.id === admin.id ? "" : */}
          <Link
            to="#"
            className="text-danger disabled"
            // onClick={() => handleDelete(admin.id)}
          >
            <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
            <UncontrolledTooltip placement="top" target="deletetooltip">
              Delete
            </UncontrolledTooltip>
          </Link>
        </div>
      ),
    },
  ]

  // ------------------------------------------------------------------------
  // Functions
  // ------------------------------------------------------------------------

  const toggle = () => {
    setModal(!modal)
  }

  const handleSubmitForm = (e, data) => {
    if(isEdit) {
      dispatch(updateAdmin(currentAdmin.id, {user: data}))
    } else {
      if (data.password === data.password_confirm) {
        dispatch(createAdmin({user: data}))
      } else {
        dispatch(setError({ detail: {password: ["Las contraseñas no coinciden"]}} ))
      }
    }

    if (errors === null) {
      setModal(false)
    } else {
      setModal(true)
    }
  }

  const handleEdit = admin => {
    setCurrentAdmin(admin)
    setIsEdit(true)
    toggle()
  }

  const handleCreate = () => {
    setCurrentAdmin({})
    setIsEdit(false)
    toggle()
  }

  const handleDelete = (id) => {
    dispatch(deleteAdmin(id))
  }

  const switchActive = (id, value) => {
    dispatch(updateAdmin(id, {user: {is_active: !value}}))
  }

  const handleSearch = (searcher, searcherType) => {
    dispatch(searchAdmin(`type=admin&${searcherType}=${searcher}`))
  }

  // ------------------------------------------------------------------------
  // Main Render
  // ------------------------------------------------------------------------

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags><title>Chat | Infast - Consola</title></MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Infast"
            breadcrumbItem="Analistas"
            options={ options }
            default={ "names" }
            handleSearch={ handleSearch }
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={tableColumns(toggle)}
                    data={admins}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={admins}
                        columns={tableColumns(toggle)}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <TableHeader handleCreate={handleCreate} />
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    // ref={node}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <ModalAdmin
        admin={currentAdmin}
        modal={modal}
        toggle={toggle}
        isEdit={isEdit}
        handleSubmit={handleSubmitForm}
        errors={errors}
      />
    </React.Fragment>
  )
}

export default Analysts
