import React from "react"
import PropTypes from "prop-types"

import { Row, Col, Modal, ModalHeader, ModalBody, Button } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"

import { useDispatch } from "react-redux"
import { putTripPayment } from "store/actions"
import { getTrips } from "store/actions"
import { updateDocumentPay } from "helpers/backend_helper"
const DocumentVerificationModalPay = ({
  modal,
  toggle,
  status,
  trip_id,
  getTravelDetail,
  number,
}) => {
  const handleOnClick = async (id, estado, number) => {
    let payment

    if (number == 2) {
      payment = "validation-last-step"
    } else if (number == 1) {
      payment = "validation-first-step"
    }

    await updateDocumentPay(id, payment, { validation: estado })
    await getTravelDetail()

    toggle()
  }

  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader
        toggle={toggle}
        tag="h4"
        className="d-flex justify-content-center"
      >
        Enviar reporte de irregularidad
      </ModalHeader>
      <ModalBody>
        <AvForm>
          <Row className="d-flex justify-content-center mb-3">
            Seleccione el motivo de la irregularidad
          </Row>

          <Row>
            <Col sm="4">
              <Button className="btn-round-selector" color="empty">
                Documento Ilegible
              </Button>
            </Col>
            <Col sm="4">
              <Button className="btn-round-selector" color="empty">
                No corresponde
              </Button>
            </Col>
            <Col sm="4">
              <Button className="btn-round-selector" color="empty">
                Documento dañado
              </Button>
            </Col>
          </Row>
          <Row className="md-flex justify-content mt-4">
            <Col sm="10">Agregar un comentario</Col>
          </Row>
          <Row className="md-flex justify-content-center mt-4">
            <Col>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="5"
              ></textarea>
            </Col>
          </Row>

          <Row className="mt-5 ">
            <Col></Col>
            <Col sm="4" className="d-flex justify-content-end mb-3">
              <Button
                className="btn-round-card h-30 w-100"
                color="white"
                onClick={toggle}
              >
                Cancelar
              </Button>
            </Col>
            <Col sm="4" className="d-flex justify-content-end mb-3">
              {status === "sended" ? (
                <Button
                  className="btn-round-card h-30 w-100"
                  color="primary"
                  onClick={() => handleOnClick(trip_id, false, number)}
                >
                  Enviar
                </Button>
              ) : (
                <Button
                  className="btn-round-card h-30 w-100"
                  color="primary"
                  onClick={() => handleOnClick(trip_id, false, number)}
                  disabled
                >
                  Enviar
                </Button>
              )}
            </Col>
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

DocumentVerificationModalPay.propTypes = {
  title: PropTypes.string,
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  status: PropTypes.string,
  trip_id: PropTypes.string,
  getTravelDetail: PropTypes.func,
  number: PropTypes.integer,
}

export default DocumentVerificationModalPay
