import React from "react"
import DocumentCard from "../../../../components/DocumentCard"
import PropTypes from "prop-types"

const DocumentSection = ({ documents }) => {
  return (
    <div className="document-section">
      {documents?.map(({ type, url, id, status }, index) => (
        <div className="document" key={index}>
          <DocumentCard
            title={type}
            url={url}
            id={id}
            status={status}
          />
        </div>
      ))}
    </div>
  )
}

DocumentSection.propTypes = {
  documents: PropTypes.array,
}

export default DocumentSection
