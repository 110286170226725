import {
  GET_CLIENTS,
  GET_CLIENTS_FAIL,
  GET_CLIENTS_SUCCESS,

  GET_CLIENT,
  GET_CLIENT_FAIL,
  GET_CLIENT_SUCCESS,

  PUT_CLIENT,
  PUT_CLIENT_FAIL,
  PUT_CLIENT_SUCCESS,

  SEARCH_CLIENT,
  SEARCH_CLIENT_FAIL,
  SEARCH_CLIENT_SUCCESS,

  FILTER_CLIENT,
  FILTER_CLIENT_FAIL,
  FILTER_CLIENT_SUCCESS,

  SET_ERROR,
} from "./actionTypes"


// ----------------------------------------------------------------------------
// List lients
// ----------------------------------------------------------------------------

export const getClients = (filters) => ({
  type: GET_CLIENTS,
  filters
})

export const getClientsSuccess = clients => ({
  type: GET_CLIENTS_SUCCESS,
  payload: clients,
})

export const getClientsFail = error => ({
  type: GET_CLIENTS_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------
// List lients
// ----------------------------------------------------------------------------

export const getClient = (id) => ({
  type: GET_CLIENT,
  id
})

export const getClientSuccess = client => ({
  type: GET_CLIENT_SUCCESS,
  payload: client,
})

export const getClientFail = error => ({
  type: GET_CLIENT_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------
// Update Client
// ----------------------------------------------------------------------------

export const updateClient = (id, data) => ({
  type: PUT_CLIENT,
  id,
  data,
})

export const updateClientSuccess = data => ({
  type: PUT_CLIENT_SUCCESS,
  payload: data,
})

export const updateClientFail = error => ({
  type: PUT_CLIENT_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------
// Clients Search
// ----------------------------------------------------------------------------

export const searchClient = filters => ({
  type: SEARCH_CLIENT,
  filters,
})

export const searchClientSuccess = clients => ({
  type: SEARCH_CLIENT_SUCCESS,
  payload: clients,
})

export const searchClientFail = error => ({
  type: SEARCH_CLIENT_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------
// Clients filter
// ----------------------------------------------------------------------------

export const filterClient = filter => ({
  type: FILTER_CLIENT,
  filter,
})

export const filterClientSuccess = drivers => ({
  type: FILTER_CLIENT_SUCCESS,
  payload: drivers,
})

export const filterClientFail = error => ({
  type: FILTER_CLIENT_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------

export const setError = error => ({
  type: SET_ERROR,
  payload: error,
})
