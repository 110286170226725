import React from "react"
import { Row, Col } from "reactstrap"
import images from "assets/images"

const UserAvatar2 = user => {
  return (
    <Row>
      <Col sm="2">
        <div className="align-self-justify me-4">
          <img
            src={user.avatar || images.avatar5}
            className="avatar-xs rounded-circle"
            alt=""
          />
        </div>
      </Col>
      <Col sm="9">
        <Row>
          <Col sm="10">
            {user.names + " " + user.first_surname + " " + user.second_surname}
          </Col>
        </Row>
        <Row>
          <Col sm="2">Email:</Col>
          <Col sm="10">{user.email}</Col>
        </Row>
      </Col>
    </Row>
  )
}

export default UserAvatar2
