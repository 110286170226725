/* CHATS */
export const POST_NEW_CHAT = "POST_NEW_CHAT"
export const POST_NEW_CHAT_SUCCESS = "POST_NEW_CHAT_SUCCESS"
export const POST_NEW_CHAT_FAIL = "POST_NEW_CHAT_FAIL"

export const GET_CHATS = "GET_CHATS"
export const GET_CHATS_SUCCESS = "GET_CHATS_SUCCESS"
export const GET_CHATS_FAIL = "GET_CHATS_FAIL"

/* MESSAGES */
export const GET_MESSAGES = "GET_MESSAGES"
export const GET_MESSAGES_SUCCESS = "GET_MESSAGES_SUCCESS"
export const GET_MESSAGES_FAIL = "GET_MESSAGES_FAIL"

export const VIEWED_MESSAGES = "VIEWED_MESSAGES"

export const PUT_UNVIEWED_MESSAGES = "PUT_UNVIEWED_MESSAGES"
export const PUT_UNVIEWED_MESSAGES_SUCCESS = "PUT_UNVIEWED_MESSAGES_SUCCESS"
export const PUT_UNVIEWED_MESSAGES_FAIL = "PUT_UNVIEWED_MESSAGES_FAIL"

export const POST_ADD_MESSAGE = "POST_ADD_MESSAGE"
export const POST_ADD_MESSAGE_SUCCESS = "POST_ADD_MESSAGE_SUCCESS"
export const POST_ADD_MESSAGE_FAIL = "POST_ADD_MESSAGE_FAIL"

export const SEARCHER_CHATS = "SEARCHER_CHATS"
export const SET_CURRENT_CHAT = "SET_CURRENT_CHAT"
