import { takeEvery, put, call } from "redux-saga/effects"

import {
  GET_DRIVERS,
  PUT_DRIVER,
  SEARCH_DRIVER,
  FILTER_DRIVER,
} from "./actionTypes"

import {
  getDriversSuccess,
  getDriversFail,

  updateDriverSuccess,
  updateDriverFail,

  searchDriverSuccess,
  searchDriverFail,

  filterDriverSuccess,
  filterDriverFail,
} from "./actions"

import {
  listUsers,
  updateUser,
  searchUsers,
  filterUsers,
} from "../../helpers/backend_helper"


function* onGetDrivers({ filters }) {
  try {
    const response = yield call(listUsers, filters)

    yield put(getDriversSuccess(response))
  } catch (error) {
    yield put(getDriversFail(error))
  }
}

// ----------------------------------------------------------------------------

function* onUpdateDriver({ id, data }) {
  try {
    const response = yield call(updateUser, id, data)

    yield put(updateDriverSuccess(response))
  } catch (error) {
    yield put(updateDriverFail(error))
  }
}

// ----------------------------------------------------------------------------

function* onSearchDrivers({ filters }) {
  try {
    const response = yield call(searchUsers, filters)

    yield put(searchDriverSuccess(response))
  } catch (error) {
    yield put(searchDriverFail(error))
  }
}

// ----------------------------------------------------------------------------

function* onFilterDrivers({ filter }) {
  try {
    const response = yield call(filterUsers, filter)

    yield put(filterDriverSuccess(response))
  } catch (error) {
    yield put(filterDriverFail(error))
  }
}

function* DriversSaga() {
  yield takeEvery(GET_DRIVERS, onGetDrivers)
  yield takeEvery(PUT_DRIVER, onUpdateDriver)
  yield takeEvery(SEARCH_DRIVER, onSearchDrivers)
  yield takeEvery(FILTER_DRIVER, onFilterDrivers)
}

export default DriversSaga
