import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"

import PropTypes from "prop-types"
import {
  Button,
  Card,
  Col,
  Container,
  Input,
  Media,
  Row,
} from "reactstrap"

//Import Scrollbar
import "react-perfect-scrollbar/dist/css/styles.css"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import {
  addMessage,
  getChats,
  getMessages,
  postNewChat,
  setCurrentChatId,

  removeNotifications,
  viewedMessages,
  updateUnViewedMessages,
  searcherChats,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import Rooms from "./rooms"
import Messages from "./messages"
import avatar4 from "../../assets/images/users/avatar-4.jpg"

const Chat = props => {
  const dispatch = useDispatch()
  const selectedChatNotification = props.location.state

  // ------------------------------------------------------------------------
  // auth User
  //
  // eslint-disable-next-line no-unused-vars
  const authUser = JSON.parse(localStorage.getItem("authUser"))
  const [currentUser, setUser] = useState(authUser.user)

  // ------------------------------------------------------------------------

  const { chats, currentRoomId, messages, loadingChats, loadingMessages, error } = useSelector(state => ({
    chats: state.chat.tempChats,
    currentRoomId: state.chat.currentChat,
    loadingChats: state.chat.loadingChats,
    messages: state.chat.messages,
    loadingMessages: state.chat.loadingMessages,
    error: state.chat.error,
  }))

  const [messageBox, setMessageBox] = useState(null)
  const [currentChat, setCurrentChat] = useState(null)
  const [currentMsg, setCurrentMsg] = useState("")
  const [searcher, setSearcher] = useState("")

  useEffect(() => {
    dispatch(getChats())
  }, [getChats])

  useEffect(() => {
    if (selectedChatNotification === undefined && chats.length > 0) {
      openChat(chats[0])
    } else if(selectedChatNotification !== undefined && chats.length === 0) {
      dispatch(postNewChat(selectedChatNotification))
    } else {
      const foundChat = chats.findIndex(c => c.client_id === selectedChatNotification.user_id);

      if (foundChat !== -1) {
        openChat(chats[foundChat])
      } else {
        dispatch(postNewChat(selectedChatNotification))
      }
    }
  }, [chats, selectedChatNotification])

  useEffect(() => {
    if (messages.length > 0) {
      scrollToBottom()
    }
  }, [messages])

  // --------------------------------------------------------------------------
  // Functions
  // --------------------------------------------------------------------------

  const openChat = (chat) => {
    setCurrentChat(chat.name)
    dispatch(setCurrentChatId(chat.id))
    dispatch(getMessages(chat.id))
  }

  const overMessages = () => {
    const unviewed = messages.find(m => m.sender_id !== currentUser.id && m.viewed === false)

    if (unviewed !== undefined) {
      dispatch(removeNotifications(currentRoomId))
      dispatch(viewedMessages(currentUser.id))
      dispatch(updateUnViewedMessages(currentRoomId))
    }
  }

  const scrollToBottom = () => {
    if (messageBox) {
      messageBox.scrollTop = messageBox.scrollHeight + 1000
    }
  }

  const newMessage = (roomId, sender) => {
    const message = {
      "message": {
        "room_id": roomId,
        "sender_id": sender,
        "type": "text",
        "message": currentMsg,
      }
    }
    dispatch(addMessage(message))
    setCurrentMsg("")
  }

  const onKeyPress = e => {
    const { key, value } = e
    if (key === "Enter") {
      setCurrentMsg(value)
      newMessage(currentRoomId, currentUser.id)
    }
  }

  const onKeyPressSearcher = (e) => {
    const { key, value } = e

    if (key === "Enter") {
      dispatch(searcherChats(searcher))
    }
  }

  const onChangeSearcher = (e) => {
    setSearcher(e.target.value)

    if(e.target.value === "") {
      dispatch(searcherChats(e.target.value))
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags><title>Chat | Infast - Consola</title></MetaTags>
        <Container fluid>
          <Breadcrumbs title="Infast" breadcrumbItem="Chat" />

          <Row>
            <Col lg="12">
              <div className="d-lg-flex">
                <div className="chat-leftsidebar me-lg-4">
                  <div className="py-4 border-bottom">
                    <Media>
                      <div className="align-self-center me-3">
                        <img
                          src={currentUser.avatar ?? avatar4}
                          className="avatar-xs rounded-circle"
                          alt="user-avatar"
                        />
                      </div>
                      <Media body>
                        <h5 className="font-size-15 mt-0 mb-1">
                          {currentUser.names}
                        </h5>
                      </Media>
                    </Media>
                  </div>

                  <div className="search-box chat-search-box py-4">
                    <div className="position-relative">
                      <Input
                        type="text"
                        className="form-control"
                        onKeyPress={onKeyPressSearcher}
                        onChange={onChangeSearcher}
                        placeholder="Buscador..."
                      />
                      <i className="bx bx-search-alt search-icon" />
                    </div>
                  </div>

                  <div className="chat-leftsidebar-nav">
                    <Rooms
                      chats={chats}
                      loadingChats={loadingChats}
                      openChat={openChat}
                      currentRoomId={currentRoomId}
                    />
                  </div>
                </div>

                <div className="w-100 user-chat">
                  {currentChat !== null ? <Card>
                    <div className="p-4 border-bottom ">
                      <Row>
                        <Col md="4" xs="9">
                          <h5 className="font-size-15 mb-1">
                            {currentChat}
                          </h5>
                        </Col>
                      </Row>
                    </div>

                    <div>
                      <Messages
                        messages={messages}
                        loadingMessages={loadingMessages}
                        setMessageBox={setMessageBox}
                        currentUser={currentUser}
                        overMessages={overMessages}
                      />

                      {/* New Message active chat */}
                      <div className="p-3 chat-input-section">
                        <Row>
                          <Col>
                            <div className="position-relative">
                              <input
                                type="text"
                                value={currentMsg}
                                onKeyPress={onKeyPress}
                                onChange={e => setCurrentMsg(e.target.value)}
                                className="form-control chat-input"
                                placeholder="Escribir mensaje..."
                              />
                            </div>
                          </Col>
                          <Col className="col-auto">
                            <Button
                              type="button"
                              color="primary"
                              onClick={() =>
                                newMessage(currentRoomId, currentUser.id)
                              }
                              className="btn btn-primary btn-rounded chat-send w-md "
                            >
                              <span className="d-none d-sm-inline-block me-2">
                                Enviar
                              </span>{" "}
                              <i className="mdi mdi-send" />
                            </Button>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card> : null}

                  {/* Fin Active chat */}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

Chat.propTypes = {
  chats: PropTypes.array,
  contacts: PropTypes.array,
  messages: PropTypes.array,
  getChats: PropTypes.func,
  getMessages: PropTypes.func,
  addMessage: PropTypes.func,
  success: PropTypes.any,
  location: PropTypes.any,
}

export default Chat
