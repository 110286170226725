import { takeEvery, put, call } from "redux-saga/effects"

import {
  POST_NEW_CHAT,
  GET_CHATS,
  GET_MESSAGES,
  POST_ADD_MESSAGE,
  PUT_UNVIEWED_MESSAGES,
} from "./actionTypes"

import {
  postNewChatSuccess,
  postNewChatFail,

  getChatsSuccess,
  getChatsFail,

  getMessagesSuccess,
  getMessagesFail,

  addMessageSuccess,
  addMessageFail,

  updateUnViewedMessagesSuccess,
  updateUnViewedMessagesFail
} from "./actions"

import { listRooms, listMessages, newMessage, newRoom, updateUnViewedMessages } from "../../helpers/backend_helper"

function* onNewChat({ data }) {
  try {
    if (data !== undefined) {
      const response = yield call(newRoom, data)
      yield put(postNewChatSuccess(response))
    }
  } catch (error) {
    yield put(postNewChatFail(error))
  }
}

function* onGetChats() {
  try {
    const response = yield call(listRooms)
    yield put(getChatsSuccess(response))
  } catch (error) {
    yield put(getChatsFail(error))
  }
}

function* onGetMessages({ roomId }) {
  try {
    if (roomId !== null) {
      const response = yield call(listMessages, roomId)
      yield put(getMessagesSuccess(response))
    }
  } catch (error) {
    yield put(getMessagesFail(error))
  }
}

function* onAddMessage({ message }) {
  try {
    const response = yield call(newMessage, message)
    yield put(addMessageSuccess(response))
  } catch (error) {
    yield put(addMessageFail(error))
  }
}

function* onUpdateUnViewedMessages({ roomId }) {
  try {
    const response = yield call(updateUnViewedMessages, roomId)
    yield put(updateUnViewedMessagesSuccess(response))
  } catch (error) {
    yield put(updateUnViewedMessagesFail(error))
  }
}

function* chatSaga() {
  yield takeEvery(GET_CHATS, onGetChats)
  yield takeEvery(GET_MESSAGES, onGetMessages)
  yield takeEvery(POST_ADD_MESSAGE, onAddMessage)
  yield takeEvery(POST_NEW_CHAT, onNewChat)
  yield takeEvery(PUT_UNVIEWED_MESSAGES, onUpdateUnViewedMessages)
}

export default chatSaga
