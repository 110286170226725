import React from "react"
import { Redirect } from "react-router-dom"

// // Pages Component
import Chat from "../pages/Chat/index"
import Analysts from "../pages/Analysts/index"
import Vehicles from "../pages/Vehicles/index"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//Clients
import ClientsList from "../pages/Clients/Clients-List/index"
import ClientProfile from "../pages/Clients/Client-Profile/index"
//Documents

//Drivers
import DriversList from "../pages/Drivers/Driver-List/index"
//Travel
import TravelView from "pages/Travels/TravelDetail"
import TravelList from "pages/Travels/TravelList"
// Maps

import MapsVector from "../pages/Maps/MapsVector"
import MapsLeaflet from "../pages/Maps/MapsLeaflet"

//Pages
import PagesStarter from "../pages/Utility/pages-starter"
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
import PagesTimeline from "../pages/Utility/pages-timeline"
import PagesFaqs from "../pages/Utility/pages-faqs"
import PagesPricing from "../pages/Utility/pages-pricing"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

//Contacts
import ContactsGrid from "../pages/Contacts/contacts-grid"
import ContactsList from "../pages/Contacts/ContactList/contacts-list"
import ContactsProfile from "../pages/Contacts/ContactsProfile/contacts-profile"

const authProtectedRoutes = [
  //chat
  { path: "/chat", component: Chat },
  { path: "/analysts", component: Analysts },
  // //profile
  { path: "/profile", component: UserProfile },

  //Clients
  { path: "/clients-list", component: ClientsList },
  { path: "/client-profile/:id", component: ClientProfile },
  //Drivers
  { path: "/drivers-list", component: DriversList },
  { path: "/vehicles", component: Vehicles },
  //Documents

  //Travels
  { path: "/travel-detail/:id", component: TravelView },
  { path: "/travel-list", component: TravelList },
  // Contacts
  { path: "/contacts-grid", component: ContactsGrid },
  { path: "/contacts-list", component: ContactsList },
  { path: "/contacts-profile", component: ContactsProfile },

  // Maps

  { path: "/maps-vector", component: MapsVector },
  { path: "/maps-leaflet", component: MapsLeaflet },

  //Utility
  { path: "/pages-starter", component: PagesStarter },
  { path: "/pages-timeline", component: PagesTimeline },
  { path: "/pages-faqs", component: PagesFaqs },
  { path: "/pages-pricing", component: PagesPricing },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/clients-list" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
]

export { authProtectedRoutes, publicRoutes }
