import React from "react"

import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody
} from "reactstrap"

import { AvField, AvForm } from "availity-reactstrap-validation"

import PropTypes from "prop-types"


const modalVehicle = ({ vehicle, vehicle_types, modal, toggle, isEdit, handleSubmit, errors }) => {
  let defaultOption = ""

  if (vehicle.vehicle_type) {
    defaultOption = vehicle.vehicle_type.id
  }

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          {isEdit ? "Editar Vehículo" : "Agregar Vehículo"}
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={ handleSubmit }>
            <Row form>
              <Col className="col-12">
                <div className="mb-3">
                  <AvField
                    name="configuration"
                    label="Configuración *"
                    type="text"
                    errorMessage="Campo inválido"
                    validate={{
                      required: { value: true },
                    }}
                    value={vehicle.configuration || ""}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="col-6">
                <div className="mb-3">
                  <AvField
                    name="charge"
                    label="Peso Máximo (kg) *"
                    type="number"
                    step=".1"
                    errorMessage="Campo inválido"
                    validate={{
                      required: { value: true },
                    }}
                    value={vehicle.charge || ""}
                  />
                </div>
              </Col>
              <Col className="col-6">
                <div className="mb-3">
                  <AvField
                    name="m3"
                    label="Metros cubicos *"
                    type="number"
                    step=".1"
                    errorMessage="Campo inválido"
                    validate={{
                      required: { value: true },
                    }}
                    value={vehicle.m3 || ""}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="col-6">
                <div className="mb-3">
                  <AvField
                    name="length"
                    label="Largo (m) *"
                    type="number"
                    step=".1"
                    errorMessage="Campo inválido"
                    validate={{
                      required: { value: true },
                    }}
                    value={vehicle.length || ""}
                  />
                </div>
              </Col>
              <Col className="col-6">
                <div className="mb-3">
                  <AvField
                    name="width"
                    label="Ancho (m) *"
                    type="number"
                    step=".1"
                    errorMessage="Campo inválido"
                    validate={{
                      required: { value: true },
                    }}
                    value={vehicle.width || ""}
                  />
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="col-6">
                <div className="mb-3">
                  <AvField
                    name="height"
                    label="Alto (m) *"
                    type="number"
                    step=".1"
                    errorMessage="Campo inválido"
                    validate={{
                      required: { value: true },
                    }}
                    value={vehicle.height || ""}
                  />
                </div>
              </Col>
              <Col className="col-6">
                <div className="mb-3">
                  <AvField
                    type="select"
                    name="vehicle_type_id"
                    label="Tipo"
                    defaultValue={defaultOption}
                  >
                    {vehicle_types.map((item) => {
                      return <option key={item.id} value={item.id}>{item.name}</option>
                    })}
                  </AvField>
                </div>
              </Col>
            </Row>

            {errors !== null ? <p className="alert-danger">{ errors.detail[Object.keys(errors.detail)[0]] }</p> : ""}

            <Row>
              <Col>
                <div className="text-end">
                  <button
                    type="button"
                    className="btn btn-rounded btn-danger w-lg"
                    onClick={() => toggle() }
                  >
                    Cancelar
                  </button>
                  <button type="submit" className="btn btn-rounded btn-success w-lg save-user gap-2">
                    Guardar
                  </button>
                </div>
              </Col>
            </Row>

          </AvForm>
        </ModalBody>
      </Modal>
    </div>
  )
}

modalVehicle.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  isEdit: PropTypes.bool,
  vehicle: PropTypes.object,
  vehicle_types: PropTypes.object,
  handleSubmit: PropTypes.func,
  errors: PropTypes.object,
}

export default modalVehicle
