import firebase from "firebase"

class FirebaseAuthBackend {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      firebase.initializeApp(firebaseConfig)
    }
  }

  preguntarPermisos = async () => {
    try {
      const messaging = firebase.messaging();

      await Notification.requestPermission().then(async permission => {
          if (permission === "denied") {
              console.log("Permission wasn't granted. Allow a retry.");
              return;
          } else if (permission === "default") {
              console.log("The permission request was dismissed.");
              return;
          }
          const token = await messaging.getToken();
          localStorage.setItem("firebaseToken", token)
          return token;
      });
    } catch (error) {
        console.error(error);
    }
  }

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message
    return errorMessage
  }
}

let _fireBaseBackend = null

/**
 * Initilize the backend
 * @param {*} config
 */
const initFirebaseBackend = config => {
  if (!_fireBaseBackend) {
    _fireBaseBackend = new FirebaseAuthBackend(config)

  }
  return _fireBaseBackend
}

/**
 * Returns the firebase backend
 */
const getFirebaseBackend = () => {
  return _fireBaseBackend
}

export { initFirebaseBackend, getFirebaseBackend }
