import React from "react"
import PropTypes from "prop-types"

import { Media } from "reactstrap"
import images from "assets/images"

import { useTranslation } from "react-i18next"

const UserAvatar = ({ userDetail }) => {
  const { t, i18n } = useTranslation()
  return (
    <div className="py-4 border-bottom">
      <Media>
        <div className="align-self-justify me-4">
          <img
            src={userDetail?.avatar || images.avatar1}
            className="avatar-xs rounded-circle"
            alt=""
          />
        </div>
        <Media body>
          <h5 className="font-size-16 mt-0 mb-0">
            {userDetail?.names == undefined
              ? "No asignado "
              : userDetail?.names + " "}

            {userDetail?.first_surname == undefined
              ? ""
              : userDetail?.first_surname + " "}

            {userDetail?.second_surname == undefined
              ? ""
              : userDetail?.second_surname + " "}
          </h5>
          <span className="text-muted mb-0 font-size-16">
            {t(userDetail?.type) || "No asignado"}/
            {t(userDetail?.sub_type) || "No asignado"}
          </span>
        </Media>
      </Media>
    </div>
  )
}

UserAvatar.propTypes = {
  userDetail: PropTypes.object,
}

export default UserAvatar
