import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"

import UserAvatarReview from "components/UserAvatar/UserAvatarReview"

const ReviewsTravel = ({ review, driver, client }) => {
  return (
    <div className="user-info w-100 position-relative">
      {
        review ?
          <div>
            <Row className="mt-5">
              <p>Transportista</p>
              <Col sm="6">
                <UserAvatarReview
                  user={driver}
                  stars={review.driver_stars}
                />
              </Col>
              <Col sm="6">
                <Row style={{ fontWeight: "600" }}>Comentarios</Row>
                <Row>{review.driver_comment}</Row>
              </Col>
            </Row>

            <Row className="mt-5">
              <p>Cliente</p>
              <Col sm="6">
                <UserAvatarReview
                  user={client}
                  stars={review.client_stars}
                />
              </Col>
              <Col sm="6">
                <Row style={{ fontWeight: "600" }}>Comentarios</Row>
                <Row>{review.client_comment}</Row>
              </Col>
            </Row>
          </div>
        :
          <Row className="mt-5" style={{ fontWeight: "600", color: "#757575"}}>
            Información aún no disponible
          </Row>
      }
      </div>
  )
}

ReviewsTravel.propTypes = {
  review: PropTypes.object,
  driver: PropTypes.object,
  client: PropTypes.object,
}

export default ReviewsTravel
