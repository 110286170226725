import React from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"

import * as moment from "moment"

const TravelContentTable = ({ travelDetail }) => {
  const { t, i18n } = useTranslation()
  const handleValidDate = date => moment(new Date(date)).format("DD MMM Y")
  const handleValidHour = date => moment(new Date(date)).format("HH:MM")

  return (
    <>
      <thead>
        <tr>
          <th>Actividades</th>
          <th>Fecha Registro</th>
          <th>Hora de registro</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{t(travelDetail.status)}</td>
          <td>{handleValidDate(travelDetail.updated_at)}</td>
          <td>{handleValidHour(travelDetail.updated_at)}</td>
        </tr>
      </tbody>
    </>
  )
}

TravelContentTable.propTypes = {
  travelDetail: PropTypes.object,
}

export default TravelContentTable
