import React from "react";

import PerfectScrollbar from "react-perfect-scrollbar";

import PropTypes from 'prop-types';
import { map } from "lodash";
import moment from "moment";


const Messages = props => {
  const { messages, loadingMessages, setMessageBox, overMessages, currentUser } = props;

  let messagesHtml;

  if (loadingMessages) {
    messagesHtml =
      <div className="spinner-border text-info m-1">
        <span className="sr-only">Loading...</span>
      </div>
  } else {
    if (messages.length > 0) {
      messagesHtml =
        <PerfectScrollbar
          style={{ height: "470px", background: "white" }}
          containerRef={ref => setMessageBox(ref)}
        >
          <li>
            <div className="chat-day-title">
              {/* <span className="title">Hoy</span> */}
            </div>
          </li>
          {map(messages, message => (
            <li
              key={"test_k" + message.id}
              className={message.sender_id === currentUser.id ? "right": ""}
            >
              <div className="conversation-list">
                <div className="ctext-wrap">
                  <div className="conversation-name">
                    {message.sender}
                  </div>
                  <p>{message.message}</p>
                  <p className="chat-time mb-0">
                    <i className={"bx bx-comment-minus align-middle me-1" + (message.viewed ? " text-success" : "") } />
                    {moment(message.inserted_at).format("DD-MM-YY hh:mm")}
                  </p>
                </div>
              </div>
            </li>
          ))}
        </PerfectScrollbar>
    }
  }

  return (
    <div className="chat-conversation p-3" onMouseEnter={() => overMessages()}>
      <ul className="list-unstyled" >
        { messagesHtml }
      </ul>
    </div>
  )
}

Messages.propTypes = {
  messages: PropTypes.any,
  loadingMessages: PropTypes.bool,
  setMessageBox: PropTypes.func,
  overMessages: PropTypes.func,
  currentUser: PropTypes.any
}

export default Messages
