import {
  GET_DRIVERS_SUCCESS,
  GET_DRIVERS_FAIL,

  PUT_DRIVER_SUCCESS,
  PUT_DRIVER_FAIL,

  SEARCH_DRIVER_FAIL,
  SEARCH_DRIVER_SUCCESS,

  FILTER_DRIVER_FAIL,
  FILTER_DRIVER_SUCCESS,

  SET_ERROR,
} from "./actionTypes"

const INIT_STATE = {
  drivers: [],
  loading: true,
  errors: null,
}

const Drivers = (state = INIT_STATE, action) => {
  switch (action.type) {

    // ----------------------------------------------------------------------------
    // List drivers
    // ----------------------------------------------------------------------------

    case GET_DRIVERS_SUCCESS:
      return {
        ...state,
        drivers: action.payload.data,
        loading: false
      }

    case GET_DRIVERS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    // ----------------------------------------------------------------------------
    // Update driver
    // ----------------------------------------------------------------------------

    case PUT_DRIVER_SUCCESS:
      const indexChat = state.drivers.findIndex(c => c.id === action.payload.data.id)
      const newArray = [...state.drivers];

      newArray[indexChat] = action.payload.data

      return {
        ...state,
        drivers: newArray,
        loading: false,
        errors: null
      }

    case PUT_DRIVER_FAIL:
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      }

    // ----------------------------------------------------------------------------
    // search drivers
    // ----------------------------------------------------------------------------

    case SEARCH_DRIVER_SUCCESS:
      return {
        ...state,
        drivers: action.payload.data,
        loading: false
      }

    case SEARCH_DRIVER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    // ----------------------------------------------------------------------------
    // filter drivers
    // ----------------------------------------------------------------------------

    case FILTER_DRIVER_SUCCESS:
      return {
        ...state,
        drivers: action.payload.data,
        loading: false
      }

    case FILTER_DRIVER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    // ----------------------------------------------------------------------------

    case SET_ERROR:
      return {
        ...state,
        errors: action.payload,
      }
      break

    default:
      return state
  }
}

export default Drivers
