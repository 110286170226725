import React from "react";

import PerfectScrollbar from "react-perfect-scrollbar";
import { Media } from "reactstrap";
import { Link } from "react-router-dom";

import PropTypes from 'prop-types';
import { map } from "lodash";
import moment from "moment";

const Rooms = props => {
  const { chats, loadingChats, openChat, currentRoomId } = props;

  let rooms;

  if (loadingChats) {
    rooms =
      <div className="spinner-border text-info m-1">
        <span className="sr-only">Loading...</span>
      </div>
  } else {
    if (chats.length > 0) {
      rooms =
        <PerfectScrollbar style={{ height: "410px" }}>
          {map(chats, chat => (
          <li
            key={chat.id + chat.status}
            className={currentRoomId === chat.id ? "active": ""}
          >
            <Link to="#" onClick={() => { openChat(chat)}}>
              <Media>
                <div className="align-self-center me-3">
                  <img
                    src={chat.avatar}
                    className="rounded-circle avatar-xs"
                    alt=""
                  />
                </div>

                <Media className="overflow-hidden" body>
                  <h5 className="text-truncate font-size-14 mb-1">
                    {chat.name}
                  </h5>
                  <p className="text-truncate mb-0">
                    {chat.last_message !== null ? chat.last_message.message : ""}
                  </p>
                </Media>
                <div className="font-size-11">
                {
                  chat.last_message !== null ?
                  moment(chat.last_message.inserted_at, 'YYYY-MM-DD HH:mm:SS').fromNow() : ""
                }
                </div>
              </Media>
            </Link>
          </li>
        ))}
        </PerfectScrollbar>
    } else {
      rooms = <p>No tiene ningun chat activo</p>
    }
  }

  return (
    <div>
      <h5 className="font-size-14 mb-3">Reciente</h5>
      <ul className="list-unstyled chat-list">
        {rooms}
      </ul>
    </div>
  )
}

Rooms.propTypes = {
  chats: PropTypes.any,
  loadingChats: PropTypes.bool,
  openChat: PropTypes.func,
  currentRoomId: PropTypes.string
}

export default Rooms
