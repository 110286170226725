import React from "react"
import { Row, Button, Col } from "reactstrap"
import PropTypes from "prop-types"
import AccountStatusSwitch from "../../Clients/Client-Profile/ClientMain/AccountStatusSwitch"
import TravelProgressBar from "./TravelProgressBar"

const TravelHeader = ({ travelDetail }) => {
  return (
    <React.Fragment>
      <Row>
        <Col>
          <div className="client-header">
            <div className="d-flex">
              <Button type="button" className="menu-button" color="light">
                <i className="bx bx-menu-alt-left"></i>
              </Button>
            </div>

            <AccountStatusSwitch />
          </div>
        </Col>
      </Row>
      <Row>
        <TravelProgressBar travelDetail={travelDetail} />
      </Row>
    </React.Fragment>
  )
}
TravelHeader.propTypes = {
  travelDetail: PropTypes.object,
}

export default TravelHeader
