import {
  GET_ADMINS_SUCCESS,
  GET_ADMINS_FAIL,

  POST_ADMIN_FAIL,
  POST_ADMIN_SUCCESS,

  PUT_ADMIN_FAIL,
  PUT_ADMIN_SUCCESS,

  DELETE_ADMIN_FAIL,
  DELETE_ADMIN_SUCCESS,

  SEARCH_ADMIN_FAIL,
  SEARCH_ADMIN_SUCCESS,

  SET_ERROR,
} from "./actionTypes"

const INIT_STATE = {
  admins: [],
  loading: true,
  errors: null,
}

const Analysts = (state = INIT_STATE, action) => {
  switch (action.type) {

    // ----------------------------------------------------------------------------
    // List admins
    // ----------------------------------------------------------------------------

    case GET_ADMINS_SUCCESS:
      return {
        ...state,
        admins: action.payload.data,
        loading: false
      }

    case GET_ADMINS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    // ----------------------------------------------------------------------------
    // New Admin
    // ----------------------------------------------------------------------------

    case POST_ADMIN_SUCCESS:
      return {
        ...state,
        admins: [...state.admins, action.payload.data],
        errors: null,
        loading: false
      }

    case POST_ADMIN_FAIL:
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      }

    // ----------------------------------------------------------------------------
    // Update Admin
    // ----------------------------------------------------------------------------

    case PUT_ADMIN_SUCCESS:
      const indexChat = state.admins.findIndex(c => c.id === action.payload.data.id)
      const newArray = [...state.admins];

      newArray[indexChat] = action.payload.data

      return {
        ...state,
        admins: newArray,
        loading: false,
        errors: null
      }

    case PUT_ADMIN_FAIL:
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      }

    // ----------------------------------------------------------------------------
    // Delete Admin
    // ----------------------------------------------------------------------------

    case DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        admins: state.admins.filter(admin => admin.id !== action.payload.data.id),
        loading: false,
        errors: null
      }

    case DELETE_ADMIN_FAIL:
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      }

    // ----------------------------------------------------------------------------
    // search admins
    // ----------------------------------------------------------------------------

    case SEARCH_ADMIN_SUCCESS:
      return {
        ...state,
        admins: action.payload.data,
        loading: false
      }

    case SEARCH_ADMIN_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    // ----------------------------------------------------------------------------

    case SET_ERROR:
      return {
        ...state,
        errors: action.payload,
      }
      break

    default:
      return state
  }
}

export default Analysts
