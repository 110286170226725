export const GET_ADMINS = "GET_ADMINS"
export const GET_ADMINS_SUCCESS = "GET_ADMINS_SUCCESS"
export const GET_ADMINS_FAIL = "GET_ADMINS_FAIL"

export const POST_ADMIN = "POST_ADMIN"
export const POST_ADMIN_SUCCESS = "POST_ADMIN_SUCCESS"
export const POST_ADMIN_FAIL = "POST_ADMIN_FAIL"

export const PUT_ADMIN = "PUT_ADMIN"
export const PUT_ADMIN_SUCCESS = "PUT_ADMIN_SUCCESS"
export const PUT_ADMIN_FAIL = "PUT_ADMIN_FAIL"

export const DELETE_ADMIN = "DELETE_ADMIN"
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS"
export const DELETE_ADMIN_FAIL = "DELETE_ADMIN_FAIL"

export const SET_ERROR = "SET_ERROR"

export const SEARCH_ADMIN = "SEARCH_ADMIN"
export const SEARCH_ADMIN_SUCCESS = "SEARCH_ADMIN_SUCCESS"
export const SEARCH_ADMIN_FAIL = "SEARCH_ADMIN_FAIL"
