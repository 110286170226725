import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("user")
  if (user) return JSON.parse(user)
  return null
}

//is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null
}

// ----------------------------------------------------------------------------
// Login Method
// ----------------------------------------------------------------------------
export const login = data => post(url.LOGIN, data)

// ----------------------------------------------------------------------------
// CRUD Clients
// ----------------------------------------------------------------------------

export const listUsers = filters => get(`${url.LIST_USERS}?${filters}`)
export const createUser = data => post(url.CREATE_USER, data)
export const getUser = id => get(`${url.DETAIL_USER}/${id}`)
export const updateUser = (id, data) => put(`${url.UPDATE_USER}/${id}`, data)
export const updateGeneralInfoUser = data =>
  put(`${url.UPDATE_GENERAL_INFO_USER}/${id}/general-information`, data)
export const deleteUser = data => put(`${url.DETAIL_USER}/${id}`, data)
export const saveFirebaseToken = data =>
  post(`${url.SAVE_FIREBASE_TOKEN}`, data)

// ----------------------------------------------------------------------------
// CRUD Documents
// ----------------------------------------------------------------------------

export const listDocuments = id => get(`${url.DETAIL_DOCUMENT}?user_id=${id}`)
export const createDocument = data => post(url.CREATE_DOCUMENT, data)
export const getDocument = id => get(`${url.DETAIL_DOCUMENT}/${id}`)
export const updateDocument = (id, status) =>
  put(`${url.DETAIL_DOCUMENT}/${id}`, status)

// ----------------------------------------------------------------------------
// Chat
// ----------------------------------------------------------------------------

export const listRooms = () => get(url.LIST_ROOMS)
export const listMessages = (roomId = "") =>
  get(`${url.LIST_MESSAGES}?room_id=${roomId}`)
export const listUnseenMessages = () => get(url.LIST_UNSEEN_MESSAGES)

export const newMessage = message => post(url.NEW_MESSAGE, message)
export const newRoom = data => post(url.NEW_ROOM, data)

export const updateUnViewedMessages = roomId =>
  put(`${url.UPDATE_UNVIEWED_MESSAGES}/${roomId}`)

// ----------------------------------------------------------------------------
// CRUD ADMINS
// ----------------------------------------------------------------------------

export const listAdmins = () => get(`${url.LIST_ADMINS}?type=admin`)
export const newAdmin = data => post(url.CREATE_USER, data)
export const updateAdmin = (id, data) => put(`${url.UPDATE_ADMIN}/${id}`, data)
export const deleteAdmin = id => del(`${url.UPDATE_ADMIN}/${id}`)

// ----------------------------------------------------------------------------
// USERS SEARCHER
// ----------------------------------------------------------------------------

export const searchUsers = filters => get(`${url.USERS_SEARCHER}?${filters}`)
export const filterUsers = filter => get(`${url.USERS_FILTER}?${filter}`)

// ----------------------------------------------------------------------------
//CRUD TRIPS
// ----------------------------------------------------------------------------

export const listTrips = () => get(url.LIST_TRIPS)
export const getTrip = (id, data) => get(`${url.GET_TRIP}/${id}`)
export const searchTrips = filters => get(`${url.TRIPS_SEARCHER}?${filters}`)
export const filterTrips = filter => get(`${url.TRIPS_FILTER}?${filter}`)
export const updateTripPay = (id, payment, validation) => put(`${url.DETAIL_PAYMENT}/${id}/${payment}`, validation)

export const updateDocumentPay = async (id, payment, status) => {
  try {
    const result = await put(`${url.DETAIL_PAYMENT}/${id}/${payment}`, status)
  } catch (error) {
    console.error(error)
  }
}

// ----------------------------------------------------------------------------
// CRUD VEHICLES
// ----------------------------------------------------------------------------

export const listVehicles = () => get(url.LIST_VEHICLES)
export const listVehicleTypes = () => get(url.LIST_VEHICLE_TYPES)
export const newVehicle = data => post(url.CREATE_VEHICLE, data)
export const updateVehicle = (id, data) =>
  put(`${url.UPDATE_VEHICLE}/${id}`, data)
