import {
  GET_VEHICLES_SUCCESS,
  GET_VEHICLES_FAIL,

  GET_VEHICLE_TYPES_SUCCESS,
  GET_VEHICLE_TYPES_FAIL,

  POST_VEHICLE_FAIL,
  POST_VEHICLE_SUCCESS,

  PUT_VEHICLE_FAIL,
  PUT_VEHICLE_SUCCESS,

  DELETE_VEHICLE_FAIL,
  DELETE_VEHICLE_SUCCESS,

  SEARCH_VEHICLE_FAIL,
  SEARCH_VEHICLE_SUCCESS,

  SET_ERROR,
} from "./actionTypes"

const INIT_STATE = {
  vehicles: [],
  vehicle_types: [],
  loading: true,
  errors: null,
}

const Analysts = (state = INIT_STATE, action) => {
  switch (action.type) {

    // ----------------------------------------------------------------------------
    // List vehicles
    // ----------------------------------------------------------------------------

    case GET_VEHICLES_SUCCESS:
      return {
        ...state,
        vehicles: action.payload.data,
        loading: false
      }

    case GET_VEHICLES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    // ----------------------------------------------------------------------------
    // List vehicle types
    // ----------------------------------------------------------------------------

    case GET_VEHICLE_TYPES_SUCCESS:
      return {
        ...state,
        vehicle_types: action.payload.data,
        loading: false
      }

    case GET_VEHICLE_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    // ----------------------------------------------------------------------------
    // New Vehicle
    // ----------------------------------------------------------------------------

    case POST_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicles: [...state.vehicles, action.payload.data],
        errors: null,
        loading: false
      }

    case POST_VEHICLE_FAIL:
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      }

    // ----------------------------------------------------------------------------
    // Update Vehicle
    // ----------------------------------------------------------------------------

    case PUT_VEHICLE_SUCCESS:
      const indexChat = state.vehicles.findIndex(c => c.id === action.payload.data.id)
      const newArray = [...state.vehicles];

      newArray[indexChat] = action.payload.data

      return {
        ...state,
        vehicles: newArray,
        loading: false,
        errors: null
      }

    case PUT_VEHICLE_FAIL:
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      }

    // ----------------------------------------------------------------------------
    // Delete Vehicle
    // ----------------------------------------------------------------------------

    case DELETE_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicles: state.vehicles.filter(vehicle => vehicle.id !== action.payload.data.id),
        loading: false,
        errors: null
      }

    case DELETE_VEHICLE_FAIL:
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      }

    // ----------------------------------------------------------------------------
    // search vehicles
    // ----------------------------------------------------------------------------

    case SEARCH_VEHICLE_SUCCESS:
      return {
        ...state,
        vehicles: action.payload.data,
        loading: false
      }

    case SEARCH_VEHICLE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    // ----------------------------------------------------------------------------

    case SET_ERROR:
      return {
        ...state,
        errors: action.payload,
      }
      break

    default:
      return state
  }
}

export default Analysts
