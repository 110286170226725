import React, { useEffect, useState, useRef } from "react"

import MetaTags from "react-meta-tags"
import PropTypes from "prop-types"
import { withRouter, Link } from "react-router-dom"
import BootstrapTable from "react-bootstrap-table-next"

import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"

import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  UncontrolledTooltip
} from "reactstrap"

//redux
import { useSelector, useDispatch } from "react-redux"

//Import Breadcrumb
import Breadcrumbs from "components/Common/BreadcrumbSearch"

import DriverEditModal from "./DriverEditModal"
import DriverHeader from "./DriverTableHeader"

import { useTranslation } from "react-i18next"
import * as moment from "moment"
import UserAvatar2 from "components/UserAvatar/UserAvatar2"

import { getDrivers, searchDriver, updateDriver } from "store/actions"

const DriverList = props => {
  const dispatch = useDispatch()

  const { orders } = useSelector(state => ({
    orders: state.ecommerce.orders,
  }))

  const selectRow = {
    mode: "checkbox",
  }

  const [userList, setUserList] = useState([])
  const [orderList, setOrderList] = useState([])

  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)

  const [currentDriver, setCurrentDriver] = useState({})

  const { drivers, errors } = useSelector(state => ({
    drivers: state.drivers.drivers,
    errors: state.drivers.errors,
  }))

  useEffect(() => {
    dispatch(getDrivers("type=driver"))
  }, [getDrivers])

  //pagination customization
  const pageOptions = {
    sizePerPage: 10,
    totalSize: orders.length, // replace later with size(orders),
    custom: true,
  }

  const { t, i18n } = useTranslation()

  const options = [
    {value: "names", name: "Nombres"},
    {value: "first_surname", name: "Apellido paterno"},
    {value: "second_surname", name: "Apellido materno"},
    {value: "full_name", name: "Nombre completo"},
    {value: "email", name: "Email"},
    {value: "phone", name: "Teléfono"},
  ]

  // ------------------------------------------------------------------------
  // Functions
  // ------------------------------------------------------------------------

  const handleSearch = (searcher, searcherType) => {
    dispatch(searchDriver(`type=driver&${searcherType}=${searcher}`))
  }

  const toggle = () => {
    setModal(!modal)
  }

  const switchActive = (id, value) => {
    dispatch(updateDriver(id, {user: {is_active: !value}}))
  }

  const handleEdit = driver => {
    if (driver.is_active === true) {
      setCurrentDriver(driver)
      setIsEdit(true)
      toggle()
    }
  }

  const handleSubmitForm = (e, data) => {
    if(modal) {
      dispatch(updateDriver(currentDriver.id, {user: data}))
    }

    if (errors === null) {
      toggle()
    }
  }

  const node = useRef()
  const onPaginationPageChange = page => {
    if (node?.current?.props?.pagination?.options) {
      node.current.props.pagination.options.onPageChange(page)
    }
  }

  const handleValidDate = date => moment(new Date(date)).format("DD MMM Y")
  const handleOrderClick = order => {
    setOrderList({
      id: order.id,
      orderId: order.orderId,
      billingName: order.billingName,
      orderdate: order.orderdate,
      total: order.total,
      paymentStatus: order.paymentStatus,
      paymentMethod: order.paymentMethod,
      badgeclass: order.badgeclass,
    })

    setIsEdit(true)
    toggle()
  }

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ]

  const UserOrderColumns = toggleModal => [
    {
      dataField: "document_status",
      text: "Status",
      sort: true,
      //eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        row.is_active === true ?
          <Link to={`/client-profile/${row.id}`}>
            <Badge
              className={"font-size-12 badge-soft-" + row.document_status}
              color={row.document_status}
              pill
            >
              {t(row.document_status)}
            </Badge>
          </Link>
        :
          <Badge
            className={"font-size-12 badge-soft-" + row.document_status}
            color={row.document_status}
            pill
          >
            {t(row.document_status)}
          </Badge>
      ),
    },
    {
      dataField: "inserted_at",
      text: "Fecha de Ingreso",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        row.is_active === true ?
          <Link to={`/client-profile/${row.id}`} className="text-body fw-bold">
            {handleValidDate(row.inserted_at)}
          </Link>
        :
          <p className="text-body fw-bold">{handleValidDate(row.inserted_at)}</p>
      ),
    },
    {
      dataField: "names",
      text: "Nombre",
      sort: true,
      formatter: (cellContent, row) => (
        row.is_active === true ?
          <Link to={`/client-profile/${row.id}`} className="text-body fw-bold">
            <UserAvatar2
              names={row.names}
              first_surname={row.first_surname}
              second_surname={row.second_surname}
              email={row.email}
              avatar={row.avatar}
            />
          </Link>
        :
          <p className="text-body fw-bold">
            <UserAvatar2
              names={row.names}
              first_surname={row.first_surname}
              second_surname={row.second_surname}
              email={row.email}
              avatar={row.avatar}

            />
          </p>
      ),
    },
    {
      dataField: "phone",
      text: "Telefono",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        row.is_active === true ?
          <Link to={`/client-profile/${row.id}`} className="text-body fw-bold">
            +55 {row.phone}
          </Link>
        :
          <p className="text-body fw-bold">+55 {row.phone}</p>
      ),
    },

    {
      dataField: "sub_type",
      text: "Tipo de Persona",
      sort: true,
      formatter: (cellContent, row) => (
        row.is_active === true ?
          <Link to={`/client-profile/${row.id}`} className="text-body fw-bold">
            {t(row.sub_type)}
          </Link>
        :
          <p className="text-body fw-bold">{t(row.sub_type)}</p>
      ),
    },
        // ------------------------------------------------------------------------
    // Actions
    // ------------------------------------------------------------------------
    {
      dataField: "view",
      isDummyField: true,
      sort: false,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <div className="form-check form-switch form-switch-lg mb-3">
          {/* Active */}
          <input
            type="checkbox"
            className="form-check-input"
            id="customSwitch1"
            checked={ row.is_active }
            onChange={() => {
              switchActive(row.id, row.is_active)
            }}
          />
          <label
            className="form-check-label"
            htmlFor="customSwitchsizelg"
          ></label>
          {/* Edit */}
          <Link
            to="#"
            className="text-secondary"
            onClick={() => handleEdit(row)}
          >
            <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
            <UncontrolledTooltip placement="top" target="edittooltip">
              Edit
            </UncontrolledTooltip>
          </Link>
        </div>
      ),
    },
  ]

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Lista transportistas | Infast</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Transportistas"
            breadcrumbItem="Todos los transportistas"
            options={ options }
            default={ "names" }
            handleSearch={ handleSearch }
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={UserOrderColumns(toggle)}
                    data={drivers}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={drivers}
                        columns={UserOrderColumns(toggle)}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <DriverHeader />
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    selectRow={selectRow}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    ref={node}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <DriverEditModal
        modal={modal}
        toggle={toggle}
        driverDetail={currentDriver}
        handleSubmit={handleSubmitForm}
      />

    </React.Fragment>
  )
}

DriverList.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onDeleteOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
}

export default withRouter(DriverList)
