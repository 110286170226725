import PropTypes from "prop-types" //YA
import React from "react" //YA
import MetaTags from "react-meta-tags" //YA
import { withRouter, Link } from "react-router-dom" //TA
import { Col, Container, Alert, Form, Row, Input } from "reactstrap" //YA
import { useSelector, useDispatch } from "react-redux" //YA

import { AvForm, AvField } from "availity-reactstrap-validation" //YA

// actions
import { loginUser, apiError } from "../../store/actions" //YA
// import images
// import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import logoinfast from "../../assets/images/infast_logo.svg"
import CarouselPage from "./CarouselPage"

const Login = props => {
  const dispatch = useDispatch()

  const { error, lol } = useSelector(state => ({
    error: state.Login.error,
    lol: state,
  }))

  // handleValidSubmit
  const handleValidSubmit = (event, values) => {
    dispatch(loginUser(values, props.history))
  }

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>Login | Infast</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="/clients-list" className="d-block auth-logo">
                        <img
                          src={logoinfast}
                          alt=""
                          height="45"
                          className="auth-logo-dark"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="30"
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Bienvenido</h5>
                      </div>

                      <div className="mt-4">
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={(e, v) => {
                            handleValidSubmit(e, v)
                          }}
                        >

                          <div className="mb-3">
                            <AvField
                              name="email"
                              label="Email"
                              value=""
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              required
                            />
                          </div>
                          <div className="mb-3">
                            <AvField
                              name="password"
                              label="Contraseña"
                              value=""
                              className="form-control"
                              placeholder="Enter password"
                              type="password"
                              required
                            />
                            <div className="">
                              <Link to="forgot-password" className="text-muted">
                                ¿Olvidaste tú contraseña?
                              </Link>
                            </div>
                          </div>

                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="auth-remember-check"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="auth-remember-check"
                            >
                              Recuerdame
                            </label>
                          </div>

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block "
                              type="submit"
                            >
                              Iniciar sesión
                            </button>
                          </div>
                        </AvForm>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Infast.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
