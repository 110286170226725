import {
  GET_VEHICLES,
  GET_VEHICLES_FAIL,
  GET_VEHICLES_SUCCESS,

  GET_VEHICLE_TYPES,
  GET_VEHICLE_TYPES_FAIL,
  GET_VEHICLE_TYPES_SUCCESS,

  POST_VEHICLE,
  POST_VEHICLE_FAIL,
  POST_VEHICLE_SUCCESS,

  PUT_VEHICLE,
  PUT_VEHICLE_FAIL,
  PUT_VEHICLE_SUCCESS,

  DELETE_VEHICLE,
  DELETE_VEHICLE_FAIL,
  DELETE_VEHICLE_SUCCESS,

  SET_ERROR,

  SEARCH_VEHICLE,
  SEARCH_VEHICLE_FAIL,
  SEARCH_VEHICLE_SUCCESS,
} from "./actionTypes"


// ----------------------------------------------------------------------------
// List vehicles
// ----------------------------------------------------------------------------

export const getVehicles = () => ({
  type: GET_VEHICLES
})

export const getVehiclesSuccess = vehicles => ({
  type: GET_VEHICLES_SUCCESS,
  payload: vehicles,
})

export const getVehiclesFail = error => ({
  type: GET_VEHICLES_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------
// List vehicle types
// ----------------------------------------------------------------------------

export const getVehicleTypes = () => ({
  type: GET_VEHICLE_TYPES
})

export const getVehicleTypesSuccess = vehicle_types => ({
  type: GET_VEHICLE_TYPES_SUCCESS,
  payload: vehicle_types,
})

export const getVehicleTypesFail = error => ({
  type: GET_VEHICLE_TYPES_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------
// New Vehicle
// ----------------------------------------------------------------------------

export const createVehicle = data => ({
  type: POST_VEHICLE,
  data,
})

export const createVehicleSuccess = data => ({
  type: POST_VEHICLE_SUCCESS,
  payload: data,
})

export const createVehicleFail = error => ({
  type: POST_VEHICLE_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------
// Update Vehicle
// ----------------------------------------------------------------------------

export const updateVehicle = (id, data) => ({
  type: PUT_VEHICLE,
  id,
  data,
})

export const updateVehicleSuccess = data => ({
  type: PUT_VEHICLE_SUCCESS,
  payload: data,
})

export const updateVehicleFail = error => ({
  type: PUT_VEHICLE_FAIL,
  payload: error,
})


// ----------------------------------------------------------------------------
// Delete Vehicle
// ----------------------------------------------------------------------------

export const deleteVehicle = id => ({
  type: DELETE_VEHICLE,
  id,
})

export const deleteVehicleSuccess = data => ({
  type: DELETE_VEHICLE_SUCCESS,
  payload: data,
})

export const deleteVehicleFail = error => ({
  type: DELETE_VEHICLE_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------

export const setError = error => ({
  type: SET_ERROR,
  payload: error,
})

// ----------------------------------------------------------------------------
// Vehicles Search
// ----------------------------------------------------------------------------

export const searchVehicle = filter => ({
  type: SEARCH_VEHICLE,
  filter,
})

export const searchVehicleSuccess = vehicles => ({
  type: SEARCH_VEHICLE_SUCCESS,
  payload: vehicles,
})

export const searchVehicleFail = error => ({
  type: SEARCH_VEHICLE_FAIL,
  payload: error,
})
