import React from "react"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"

import { NavItem, Nav, NavLink, Col, Row } from "reactstrap"

const ClientDocumentInfo = ({ id, type, status }) => {
  const { t, i18n } = useTranslation()

  const scrollToDocument = () => {
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
    })
  }

  return (
    <ul className="sub-menu" aria-expanded="false">
      <Nav tabs className="nav-tabs-custom" vertical role="tablist">
        <NavItem>
          <NavLink onClick={() => scrollToDocument()}>
            <Row>
              <Col md="10">
                <span className="label-nav font-size-14">{t(type)}</span>
              </Col>
              <Col md="2">
                <i
                  className={
                    status === "validated"
                      ? "bx bxs-check-circle text-" + status
                      : "mdi mdi-clock text-" + status
                  }
                ></i>
              </Col>
            </Row>
          </NavLink>
        </NavItem>
      </Nav>
    </ul>
  )
}

ClientDocumentInfo.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  status: PropTypes.string,
}

export default ClientDocumentInfo
