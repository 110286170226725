import React from "react"
import { Button } from "reactstrap"

import AccountStatusSwitch from "./AccountStatusSwitch"

const ClientHeader = () => {
  return (
    <div className="client-header" >
      <div className="d-flex">
        <Button type="button" className="menu-button" color="light">
          <i className="bx bx-menu-alt-left"></i>
        </Button>

        <div className="actions">
          <div className="d-flex align-items-center">

          </div>

          {/* <label>Fecha de aprobacion:mm/dd/aaaa hh:mm</label> */}
        </div>
      </div>

      <AccountStatusSwitch />
    </div>
  )
}

export default ClientHeader
