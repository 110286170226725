import React, { useState } from "react"
import PropTypes from "prop-types"

import { Link } from "react-scroll"
import PhotoModal from "./PhotoModal"

const PhotoCardModal = ({ photo }) => {
  const [modal, setModal] = useState(false)

  const handleOnClick = () => {
    toggle()
  }

  const toggle = () => {
    setModal(!modal)
  }

  return (
    <div className="document-card" style={{marginTop: "0px"}}>
      <Link to="#" className="text-success" onClick={() => handleOnClick()}>
        <img src={photo}  />
      </Link>

      <PhotoModal
        modal={modal}
        toggle={toggle}
        imgUrl={photo}
      />
    </div>
  )
}

PhotoCardModal.propTypes = {
  photo: PropTypes.string,
}

export default PhotoCardModal
