import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap"
import SimpleBar from "simplebar-react"

//Import images
import avatar4 from "../../../assets/images/users/avatar-4.jpg"

//i18n
import { withTranslation } from "react-i18next"

import { useSelector, useDispatch } from "react-redux"

import { listNotifications, newNotificationSuccess, addMessageSuccess } from "store/actions"
import { map } from "lodash";
import moment from "moment";

import firebase from "firebase/app";
import "firebase/messaging";

const NotificationDropdown = props => {

  const dispatch = useDispatch()

  const { notifications, currentChat } = useSelector(state => ({
    notifications: state.Notification.notifications,
    currentChat: state.chat.currentChat,
  }))

  const onMessage = event => {
    console.log("Firebase Received Message");

    if (event.data.data) {
      const notification = JSON.parse(event.data.data.notification)

      if(notification.type === "message") {
        if (window.location.pathname == "/chat") {
          dispatch(addMessageSuccess(notification))
        } else {
          dispatch(newNotificationSuccess(notification))
        }
      }
    }

    if (firebase.messaging.isSupported()) {
      firebase.messaging().onMessage(onMessage);
    }
  }

  useEffect(() => {
    dispatch(listNotifications())
  }, [listNotifications])

  useEffect(() => {
    navigator.serviceWorker.addEventListener("message", onMessage)
  }, [navigator.serviceWorker])

  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon "
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="bx bx-bell bx-tada" />
          <span className="badge bg-danger rounded-pill">{notifications.length}</span>
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu dropdown-menu-lg p-0 dropdown-menu-end">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0"> {props.t("Notifications")} </h6>
              </Col>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {map(notifications, notification => (
              <Link
                key={ notification.id }
                to={{
                  pathname: "/chat",
                  state: { user_id: notification.sender_id }
                }}
                className="text-reset notification-item"
              >
                <div className="media">
                  <img
                    src={avatar4}
                    className="me-3 rounded-circle avatar-xs"
                    alt="user-pic"
                  />
                  <div className="media-body">
                    <h6 className="mt-0 mb-1">{ notification.title || notification.sender_name }</h6>
                    <div className="font-size-12 text-muted">
                      <p className="mb-1">
                        { notification.body || notification.message }
                      </p> <p className="mb-0">
                        <i className="mdi mdi-clock-outline" />
                        { moment(notification.inserted_at, 'YYYY-MM-DD HH:mm:SS').fromNow() }
                      </p>
                    </div>
                  </div>
                  </div>
              </Link>
            ))}
          </SimpleBar>

        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

NotificationDropdown.propTypes = {
  t: PropTypes.any,
}

export default withTranslation()(NotificationDropdown)
