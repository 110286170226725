import {
  GET_TRIPS_SUCCESS,
  GET_TRIPS_FAIL,
  GET_TRIP_SUCCESS,
  GET_TRIP_FAIL,
  PUT_TRIP_PAYMENT_SUCCESS,
  PUT_TRIP_PAYMENT_FAIL,
  SEARCH_TRIP_FAIL,
  SEARCH_TRIP_SUCCESS,
  FILTER_TRIP_FAIL,
  FILTER_TRIP_SUCCESS,
  SET_ERROR,
} from "./actionTypes"

const INIT_STATE = {
  trips: [],
  trip: [],
  loading: true,
  errors: null,
}

const Trips = (state = INIT_STATE, action) => {
  switch (action.type) {
    // ----------------------------------------------------------------------------
    // List trips
    // ----------------------------------------------------------------------------

    case GET_TRIPS_SUCCESS:
      return {
        ...state,
        trips: action.payload.data,
        loading: false,
      }

    case GET_TRIPS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    // ----------------------------------------------------------------------------
    // Detail trip
    // ----------------------------------------------------------------------------

    case GET_TRIP_SUCCESS:
      return {
        ...state,
        trip: action.payload.data,
        loading: false,
      }

    case GET_TRIP_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    // ----------------------------------------------------------------------------
    // Update trip payment
    // ----------------------------------------------------------------------------

    case PUT_TRIP_PAYMENT_SUCCESS:
      return {
        ...state,

        loading: false,
      }

    case PUT_TRIP_PAYMENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    // ----------------------------------------------------------------------------
    // search trips
    // ----------------------------------------------------------------------------

    case SEARCH_TRIP_SUCCESS:
      return {
        ...state,
        trips: action.payload.data,
        loading: false,
      }

    case SEARCH_TRIP_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    // ----------------------------------------------------------------------------
    // search trips
    // ----------------------------------------------------------------------------

    case FILTER_TRIP_SUCCESS:
      return {
        ...state,
        trips: action.payload.data,
        loading: false,
      }

    case FILTER_TRIP_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    // ----------------------------------------------------------------------------

    case SET_ERROR:
      return {
        ...state,
        errors: action.payload,
      }
      break

    default:
      return state
  }
}

export default Trips
