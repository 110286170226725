import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"

import { withRouter } from "react-router-dom"

import { Card, Col, Container, Row } from "reactstrap"

import Breadcrumbs from "components/Common/BreadcrumbSearch"

import ClientHeader from "./ClientTableHeader"
import { useSelector, useDispatch } from "react-redux"

import { getClients, searchClient } from "store/actions"

const ClientsList = props => {
  const dispatch = useDispatch()

  const { clients, errors } = useSelector(state => ({
    clients: state.clients.clients,
    errors: state.clients.errors,
  }))

  const options = [
    {value: "names", name: "Nombres"},
    {value: "first_surname", name: "Apellido paterno"},
    {value: "second_surname", name: "Apellido materno"},
    {value: "full_name", name: "Nombre completo"},
    {value: "email", name: "Email"},
    {value: "phone", name: "Teléfono"},
  ]

  useEffect(() => {
    dispatch(getClients("type=client"))
  }, [getClients])

  // ------------------------------------------------------------------------
  // Functions
  // ------------------------------------------------------------------------

  const handleSearch = (searcher, searcherType) => {
    dispatch(searchClient(`type=client&${searcherType}=${searcher}`))
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Lista Clientes | Infast</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Clientes"
            breadcrumbItem="Todos los clientes"
            options={ options }
            default={ "names" }
            handleSearch={ handleSearch }
          />
          <Row>
            <Col xs="12">
              <Card>
                <ClientHeader userList={clients} errors={errors} />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(ClientsList)
