export const GET_DRIVERS = "GET_DRIVERS"
export const GET_DRIVERS_SUCCESS = "GET_DRIVERS_SUCCESS"
export const GET_DRIVERS_FAIL = "GET_DRIVERS_FAIL"

export const PUT_DRIVER = "PUT_DRIVER"
export const PUT_DRIVER_SUCCESS = "PUT_DRIVER_SUCCESS"
export const PUT_DRIVER_FAIL = "PUT_DRIVER_FAIL"

export const SEARCH_DRIVER = "SEARCH_DRIVER"
export const SEARCH_DRIVER_SUCCESS = "SEARCH_DRIVER_SUCCESS"
export const SEARCH_DRIVER_FAIL = "SEARCH_DRIVER_FAIL"

export const FILTER_DRIVER = "FILTER_DRIVER"
export const FILTER_DRIVER_SUCCESS = "FILTER_DRIVER_SUCCESS"
export const FILTER_DRIVER_FAIL = "FILTER_DRIVER_FAIL"

export const SET_ERROR = "SET_ERROR"
