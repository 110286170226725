import React from "react"
import PropTypes from "prop-types"

import * as moment from "moment"

import UserAvatar3 from "components/UserAvatar/UserAvatar3"

const TravelOffersList = ({ amount, inserted_at, user, is_best, index }) => {
  const handleValidDate = date => moment(new Date(date)).format("DD MM Y HH:MM");

  const optionsMoney = { style: 'currency', currency: 'MXN' };
  const numFormat = new Intl.NumberFormat('es-MX', optionsMoney);

  return (
    <tbody>
      <tr>
        <td>#{index}</td>
        <td>{handleValidDate(inserted_at)}</td>
        <td>
          {user ? <UserAvatar3 user={user} /> : "Nombre no disponible"}
        </td>
        <th>{numFormat.format(amount)}</th>
        <td>{is_best ? "Mejor Oferta" : ""}</td>
      </tr>
    </tbody>
  )
}

TravelOffersList.propTypes = {
  index: PropTypes.number,
  amount: PropTypes.string,
  inserted_at: PropTypes.string,
  is_best: PropTypes.bool,
  user: PropTypes.object,
}

export default TravelOffersList
