import React, { useState } from "react"
import PropTypes from "prop-types"

import { Col, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import DocumentCardModalPay from "./DocumentCardModalPay"
import DocumentVerificationModalPay from "./DocumentVerificationModalPay"
import DocumentAlertModalPay from "./DocumentAlertModalPay"

import "./DocumentCard.scss"

const DocumentCardPay = ({
  title,
  imgUrl,
  id,
  altText = "",
  trip_id,
  status,
  getTravelDetail,
  number,
}) => {
  // ------------------------------------------------------------------------
  // State
  // ------------------------------------------------------------------------

  const [modal, setModal] = useState(false)
  const [modalVerification, setModalVerification] = useState(false)
  const [modalAlert, setModalAlert] = useState(false)
  const { t, i18n } = useTranslation()

  // ------------------------------------------------------------------------
  // Functions
  // ------------------------------------------------------------------------

  const handleOnClick = () => {
    toggle()
  }

  const handleOnClickVerification = () => {
    toggleVerification()
  }
  const handleOnClickAlert = () => {
    toggleAlert()
  }

  const toggle = () => {
    setModal(!modal)
  }

  const toggleVerification = () => {
    setModalVerification(!modalVerification)
  }

  const toggleAlert = () => {
    setModalAlert(!modalAlert)
  }

  return (
    <div className="document-card" id={id}>
      <Row>
        <p>{t(title)}</p>
        <Link to="#" className="text-success" onClick={() => handleOnClick()}>
          <img src={imgUrl} alt={altText} />
        </Link>
      </Row>
      <Row className="mx-auto">
        <Col>
          <Link
            to="#"
            className="text-empty"
            onClick={() => {
              status == "sended" ? handleOnClickVerification() : ""
            }}
          >
            <i
              className={
                status === "irregularity"
                  ? "bx bxs-x-circle text-" + status
                  : "bx bx-x-circle"
              }
            ></i>
          </Link>
        </Col>
        <Col>
          <Link
            to="#"
            className="text-empty"
            onClick={() => {
              status == "sended" ? handleOnClickAlert() : ""
            }}
          >
            <i
              className={
                status === "validated"
                  ? "bx bxs-check-circle text-" + status
                  : "bx bx-check-circle"
              }
            ></i>
          </Link>
        </Col>
      </Row>

      <DocumentCardModalPay
        modal={modal}
        toggle={toggle}
        title={title}
        imgUrl={imgUrl}
        status={status}
        handleOnClickAlert={handleOnClickAlert}
        handleOnClickVerification={handleOnClickVerification}
      />

      <DocumentVerificationModalPay
        title={title}
        modal={modalVerification}
        toggle={toggleVerification}
        status={status}
        trip_id={trip_id}
        getTravelDetail={getTravelDetail}
        number={number}
      />

      <DocumentAlertModalPay
        title={title}
        modal={modalAlert}
        toggle={toggleAlert}
        status={status}
        trip_id={trip_id}
        getTravelDetail={getTravelDetail}
        number={number}
      />
    </div>
  )
}

DocumentCardPay.propTypes = {
  title: PropTypes.string,
  imgUrl: PropTypes.string,
  id: PropTypes.string,
  trip_id: PropTypes.string,
  altText: PropTypes.string,
  status: PropTypes.string,
  getTravelDetail: PropTypes.func,
  number: PropTypes.integer,
}

export default DocumentCardPay
