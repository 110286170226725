import React from "react"
import PropTypes from "prop-types"
import { Row, Col, Button, ButtonGroup } from "reactstrap"

import { useDispatch } from "react-redux"

import { filterDriver } from "store/actions"

const DriverHeader = ({ setUserList }) => {
  const dispatch = useDispatch()

  const filter = (value) => {
    dispatch(filterDriver(`type=driver&${value}`))
  }

  return (
    <div>
      <Row className="mb-2">
        <Col sm="12" md="3">
          <div className="search-box me-2 mb-2 d-inline-block">
            <h5>Todos los transportistas</h5>
          </div>
        </Col>
        <Col sm="12" md="6" xl="7">
          <div className="text-sm-left">
            <Button
              type="button"
              color="primary"
              className="status-button me-2"
              onClick={ () => filter("doc_status=empty&gi_status=empty") }
            >
              Nuevo
            </Button>
            <Button
              type="button"
              color="danger"
              className="status-button me-2"
              onClick={ () => filter("doc_status=irregularity&gi_status=irregularity") }
            >
              Irregularidad
            </Button>
            <Button
              type="button"
              color="info"
              className="status-button me-2"
              onClick={ () => filter("doc_status=sended&gi_status=sended") }
            >
              Por validar
            </Button>
            <Button
              type="button"
              color="success"
              className="status-button me-2"
              onClick={ () => filter("doc_status=validated&gi_status=validated") }
            >
              Validado
            </Button>


          </div>
        </Col>
        <Col sm="12" md="3" xl="1">
          <ButtonGroup>
            <Button
              color="warning"
              onClick={ () => filter("date=first_day_month") }
            >
              Mes
            </Button>
            <Button
              color="warning"
              onClick={ () => filter("date=first_day_week") }
            >
              Semana
            </Button>
            <Button
              color="warning"
              onClick={ () => filter("date=today") }
            >
              Dia
            </Button>
          </ButtonGroup>
        </Col>
      </Row>
    </div>
  )
}

DriverHeader.propTypes = {
  setUserList: PropTypes.func,
}

export default DriverHeader
