import React from "react"
import PropTypes from "prop-types"
import { Row } from "reactstrap"

import PhotoCardModal from "components/PhotoModal"

const CompletionEvidenceTravel = ({ photo }) => {
  return (
    <div className="user-info w-100 position-relative">
      <Row>
        <p style={{ fontWeight: "800", paddingLeft: "0" }}>
          Evidencia de finalización
          {photo ? <i
            className={"mdi mdi-circle " + (photo ? "text-success" : "text-danger")}
            style={{fontSize: "0.8rem"}}
          ></i>:<div/>}
        </p>

        {photo ?
          <div style={{ paddingLeft: "0" }}>
            <PhotoCardModal photo={photo} />
          </div>
        :
          <Row style={{ fontWeight: "600", color: "#757575"}}>
            Información aún no disponible
          </Row>
        }
      </Row>
    </div>
  )
}

CompletionEvidenceTravel.propTypes = {
  photo: PropTypes.string,
}

export default CompletionEvidenceTravel
