import { takeEvery, put, call } from "redux-saga/effects"

import {
  GET_VEHICLES,
  GET_VEHICLE_TYPES,
  POST_VEHICLE,
  PUT_VEHICLE,
  // DELETE_VEHICLE,
  SEARCH_VEHICLE,
} from "./actionTypes"

import {
  getVehiclesSuccess,
  getVehiclesFail,

  getVehicleTypesSuccess,
  getVehicleTypesFail,

  createVehicleSuccess,
  createVehicleFail,

  updateVehicleSuccess,
  updateVehicleFail,

  // deleteVehicleSuccess,
  // deleteVehicleFail,

  // searchVehicleSuccess,
  // searchVehicleFail,
} from "./actions"

import {
  listVehicles,
  newVehicle,
  updateVehicle,
  listVehicleTypes,
} from "../../helpers/backend_helper"


function* onGetVehicles() {
  try {
    const response = yield call(listVehicles)

    yield put(getVehiclesSuccess(response))
  } catch (error) {
    yield put(getVehiclesFail(error))
  }
}

// ----------------------------------------------------------------------------

function* onGetVehicleTypes() {
  try {
    const response = yield call(listVehicleTypes)

    yield put(getVehicleTypesSuccess(response))
  } catch (error) {
    yield put(getVehicleTypesFail(error))
  }
}

// ----------------------------------------------------------------------------

function* onCreateVehicle({ data }) {
  try {
    const response = yield call(newVehicle, data)

    yield put(createVehicleSuccess(response))
  } catch (error) {
    yield put(createVehicleFail(error))
  }
}

// ----------------------------------------------------------------------------

function* onUpdateVehicle({ id, data }) {
  try {
    const response = yield call(updateVehicle, id, data)

    yield put(updateVehicleSuccess(response))
  } catch (error) {
    yield put(updateVehicleFail(error))
  }
}

// // ----------------------------------------------------------------------------

// function* onDeleteVehicle({ id }) {
//   try {
//     const response = yield call(deleteVehicle, id)

//     yield put(deleteVehicleSuccess(response))
//   } catch (error) {
//     yield put(deleteVehicleFail(error))
//   }
// }

// // ----------------------------------------------------------------------------

// function* onSearchVehicles({ filter }) {
//   try {
//     const response = yield call(searchUsers, filter)

//     yield put(searchVehicleSuccess(response))
//   } catch (error) {
//     yield put(searchVehicleFail(error))
//   }
// }

function* VehiclesSaga() {
  yield takeEvery(GET_VEHICLES, onGetVehicles)
  yield takeEvery(GET_VEHICLE_TYPES, onGetVehicleTypes)
  yield takeEvery(POST_VEHICLE, onCreateVehicle)
  yield takeEvery(PUT_VEHICLE, onUpdateVehicle)
  // yield takeEvery(DELETE_VEHICLE, onDeleteVehicle)
  // yield takeEvery(SEARCH_VEHICLE, onSearchVehicles)
}

export default VehiclesSaga
