import React, { useEffect, useState } from "react"
import { useParams } from "react-router"
import { Container, Row } from "reactstrap"

import { useSelector, useDispatch } from "react-redux"

import ClientMain from "./ClientMain/"
import ClientSideBar from "./ClientSideBar/"

import { getClient, getDocuments } from "store/actions"

const ClientProfile = () => {
  const dispatch = useDispatch()
  const { id } = useParams()

  // ------------------------------------------------------------------------
  // State
  // ------------------------------------------------------------------------

  const { client, documents, errors } = useSelector(state => ({
    client: state.clients.client,
    documents: state.documents.documents,
    errors: state.clients.errors
  }))

  useEffect(() => {
    dispatch(getClient(id))
    dispatch(getDocuments(id))
  }, [getClient, getDocuments])

  return (
    <div >
      <Container fluid className="page-content" >
        { client !== null ?
        <Row>
          <ClientSideBar
            documentsList={documents}
            userDetail={client}
          />

          <ClientMain
            documents={documents}
            userDetail={client}
            errors={errors}
          />
        </Row>
        : ""}
      </Container>
    </div>
  )
}
export default ClientProfile
