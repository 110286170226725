import React, { useState } from "react"
import PropTypes from "prop-types"

import { Col, Row } from "reactstrap"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import DocumentCardModal from "./DocumentCardModal"
import DocumentVerificationModal from "./DocumentVerificationModal"
import DocumentAlertModal from "./DocumentAlertModal"

import "./DocumentCard.scss"

const DocumentCard = ({ title, url, id, altText = "", status }) => {

  // ------------------------------------------------------------------------
  // State
  // ------------------------------------------------------------------------

  const [modal, setModal] = useState(false)
  const [modalVerification, setModalVerification] = useState(false)
  const [modalAlert, setModalAlert] = useState(false)

  const { t, i18n } = useTranslation()
  const isPdf = url.includes(".pdf?Expires")

  // ------------------------------------------------------------------------
  // Functions
  // ------------------------------------------------------------------------

  const handleOnClick = () => {
    toggle()
  }

  const handleOnClickVerification = () => {
    toggleVerification()
  }
  const handleOnClickAlert = () => {
    toggleAlert()
  }

  const toggle = () => {
    setModal(!modal)
  }

  const toggleVerification = () => {
    setModalVerification(!modalVerification)
  }

  const toggleAlert = () => {
    setModalAlert(!modalAlert)
  }

  return (
    <div className="document-card" id={id}>
      <Row>
        <p>{t(title)}</p>
        <Link to="#" className="text-success" onClick={() => handleOnClick()}>
          { isPdf ?
            <i
              className="mdi mdi-file-pdf"
              style={{ display: "block", fontSize: "4.4rem", textAlign: "center", color: "#b71c1c" }}
            >
            </i>
            : <img src={url} alt={altText} />
          }
        </Link>
      </Row>

      <Row className="mx-auto">
        <Col>
          <Link
            to="#"
            className="text-empty"
            onClick={() => handleOnClickVerification()}
          >
            <i
              className={
                status === "irregularity"
                  ? "bx bxs-x-circle text-" + status
                  : "bx bx-x-circle"
              }
            ></i>
          </Link>
        </Col>
        <Col>
          <Link
            to="#"
            className="text-empty"
            onClick={() => handleOnClickAlert()}
          >
            <i
              className={
                status === "validated"
                  ? "bx bxs-check-circle text-" + status
                  : "bx bx-check-circle"
              }
            ></i>
          </Link>
        </Col>
      </Row>

      <DocumentCardModal
        modal={modal}
        toggle={toggle}
        title={title}
        url={url}
        isPdf={isPdf}
        status={status}
        handleOnClickAlert={handleOnClickAlert}
        handleOnClickVerification={handleOnClickVerification}
      />

      <DocumentVerificationModal
        modal={modalVerification}
        toggle={toggleVerification}
        id={id}
      />

      <DocumentAlertModal
        modal={modalAlert}
        toggle={toggleAlert}
        id={id}
      />
    </div>
  )
}

DocumentCard.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  id: PropTypes.string,
  altText: PropTypes.string,
  status: PropTypes.string,
}

export default DocumentCard
