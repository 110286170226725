import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"

import {
  Card,
  Button,
  Progress,
  NavItem,
  Nav,
  NavLink,
  Col,
  Row,
} from "reactstrap"

import UserAvatar from "../../../../components/UserAvatar/UserAvatar"
import PropTypes from "prop-types"
import ClientDocumentInfo from "./ClientDocumentInfo"

const ClientSideBar = ({ documentsList, userDetail }) => {
  const history = useHistory()

  const openChat = () => {
    history.push({
      pathname: "/chat",
      state: { user_id: userDetail.id },
    })
  }

  const status = documentsList?.filter(
    ({ status }, index) => status == "validated"
  )

  const statusPercentege = (status?.length * 100) / documentsList?.length

  return (
    <Card className="email-leftbar mb-0">
      <Link to="/clients-list">
        <Row >
          <Col md="2">
            <div className="i">
              <i className="bx bx-chevron-left iconlarge"></i>
            </div>
          </Col>
          <Col>
            <h5>Clientes/Detalle de perfil</h5>
          </Col>
        </Row>
      </Link>
      <div style={{overflowY: 'auto', height: '100%', overflowX: 'hidden'}}>
        <UserAvatar userDetail={userDetail} />
        <Button
          color="primary"
          className="btn-round h-30 w-100"
          onClick={() => {
            openChat()
          }}
        >
          <label className="label-button">Escribir Mensaje</label>
          <i className="bx bxs-comment-detail icon-small"></i>
        </Button>
        <Row className="label-top">
          <Col md="10">
            <label>Progreso de Validacion</label>
          </Col>
          <Col>{Math.round(statusPercentege)}%</Col>
          <Col>
            <Progress color="success" value={statusPercentege}></Progress>
          </Col>
        </Row>
        <Nav tabs className="nav-tabs-custom" vertical role="tablist">
          <NavItem>
            <NavLink>
              <div className="d-flex justify-content-between">
                <Row>
                  <Col md="2">
                    <i className="bx bxs-user"></i>
                  </Col>
                  <Col md="8">
                    <span className="label-nav font-size-16">
                      Informacion personal
                    </span>
                  </Col>
                  <Col md="2">
                    <i
                      className={
                        userDetail.document_status === "validated"
                          ? "bx bxs-check-circle text-" +
                            userDetail.document_status
                          : "mdi mdi-clock text-" + userDetail.document_status
                      }
                    ></i>
                  </Col>

                  <span className="sub-label-nav font-size-12">
                    Verifica la informacion basica del perfil
                  </span>
                </Row>
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink>
              <Row>
                <Col md="2">
                  <i className="bx bxs-camera-movie"></i>
                </Col>
                <Col md="7">
                  <span className="label-nav font-size-16">Documentos</span>
                </Col>
                <Col>
                  <span className="sub-label-nav font-size-12">
                    Valida cada documento
                  </span>
                </Col>
              </Row>
            </NavLink>
            {documentsList?.map(({ type, id, status }, index) => (
              <div className="document" key={index}>
                <ClientDocumentInfo id={id} type={type} status={status} />
              </div>
            ))}
          </NavItem>
          <NavItem>
            <NavLink>
              <Row>
                <Col md="2">
                  <i className="bx bxs-truck"></i>
                </Col>
                <Col>
                  <span className="label-nav font-size-16">
                    Historial de viajes
                  </span>
                </Col>
              </Row>
            </NavLink>
          </NavItem>
        </Nav>
      </div>
    </Card>
  )
}

ClientSideBar.propTypes = {
  documentsList: PropTypes.array,
  userDetail: PropTypes.object,
}

export default ClientSideBar
