import React from "react"
import PropTypes from "prop-types"

import { Row, Col } from "reactstrap"
import images from "assets/images"

const UserAvatar3 = ({ user }) => {
  return (
    <Row>
      <Col sm="2">
        <div className="align-self-justify me-4">
          <img
            src={user.avatar || images.avatar1}
            className="avatar-xs rounded-circle"
            alt=""
          />
        </div>
      </Col>
      <Col sm="9">
        <Row>
          <Col sm="10">
            {user.names + " " + user.first_surname + " " + user.second_surname}
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

UserAvatar3.propTypes = {
  user: PropTypes.object,
}

export default UserAvatar3
