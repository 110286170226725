import React from "react"
import { Row, Col, Modal, ModalHeader, ModalBody } from "reactstrap"
import { AvField, AvForm } from "availity-reactstrap-validation"
import PropTypes from "prop-types"
import TravelList from "."
const TravelEdit = ({ modal, toggle, isEdit, handleValidOrderSubmit }) => {
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} tag="h4">
          {!!isEdit ? "Editar Cliente" : "Agregar Cliente"}
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleValidOrderSubmit}>
            <Row form>
              <Col className="col-12">
                <div className="mb-3">
                  <AvField
                    name="clientName"
                    label="Nombre"
                    type="text"
                    errorMessage="Invalid  clientId"
                    validate={{
                      required: { value: true },
                    }}
                    //value={orderList.orderId || ""}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    name="clientSurname"
                    label="Apellido"
                    type="text"
                    errorMessage="Invalid Billing Name"
                    validate={{
                      required: { value: true },
                    }}
                    //value={orderList.billingName || ""}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    name="dateUpdate"
                    label="Fecha Atualizacion"
                    type="date"
                    errorMessage="Invalid Date"
                    validate={{
                      required: { value: true },
                    }}
                    //value={orderList.orderdate || ""}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    name="phone"
                    label="Telefono"
                    type="text"
                    errorMessage="Invalid phone"
                    validate={{
                      required: { value: true },
                    }}
                    //value={orderList.total || ""}
                  />
                </div>
                <div className="mb-3">
                  <AvField
                    name="typePerson"
                    label="Tipo Persona"
                    type="select"
                    className="form-select"
                    errorMessage="Invalid Payment Status"
                    validate={{
                      required: { value: true },
                    }}
                    //value={orderList.paymentStatus || ""}
                  >
                    <option>Paid</option>
                    <option>Chargeback</option>
                    <option>Refund</option>
                  </AvField>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="text-end">
                  <button type="submit" className="btn btn-success save-user">
                    Guardar
                  </button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    </div>
  )
}
TravelEdit.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  isEdit: PropTypes.bool,
  handleValidOrderSubmit: PropTypes.func,
}
export default TravelEdit
