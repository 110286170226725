import {
  GET_CHATS_SUCCESS,
  GET_CHATS_FAIL,

  GET_MESSAGES_SUCCESS,
  GET_MESSAGES_FAIL,

  VIEWED_MESSAGES,

  POST_ADD_MESSAGE_SUCCESS,
  POST_ADD_MESSAGE_FAIL,

  POST_NEW_CHAT_SUCCESS,
  POST_NEW_CHAT_FAIL,

  PUT_UNVIEWED_MESSAGES_SUCCESS,
  PUT_UNVIEWED_MESSAGES_FAIL,

  SEARCHER_CHATS,
  SET_CURRENT_CHAT,
} from "./actionTypes"

const INIT_STATE = {
  newChat: null,
  chats: [],
  currentChat: null,
  tempChats: [],
  loadingChats: true,
  messages: [],
  loadingMessages: true,
  error: {},
}

const Chat = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_NEW_CHAT_SUCCESS:
      return {
        ...state,
        chats: [...state.chats, action.payload],
        tempChats: [...state.tempChats, action.payload],
      }

      case POST_NEW_CHAT_FAIL:
        return {
          ...state,
          error: action.payload.response.data,
        }

    // ------------------------------------------------------------------------

    case GET_CHATS_SUCCESS:
      return {
        ...state,
        chats: action.payload.data,
        tempChats: action.payload.data,
        loadingChats: false
      }

    case GET_CHATS_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingChats: false
      }

    // ------------------------------------------------------------------------

    case GET_MESSAGES_SUCCESS:
      return {
        ...state,
        messages: action.payload.data,
        loadingMessages: false
      }

    case GET_MESSAGES_FAIL:
      return {
        ...state,
        error: action.payload,
        loadingMessages: false
      }

    // ------------------------------------------------------------------------

    case VIEWED_MESSAGES:
      const unviewed = state.messages.filter(m => m.sender_id !== action.currentUserId && m.viewed === false);
      const newMessages = [...state.messages];

      unviewed.forEach((item, i) => {
        const index = newMessages.findIndex(m => m.id === item.id)
        newMessages[index].viewed = true
      });

      return {
        ...state,
        messages: newMessages
      }

    // ------------------------------------------------------------------------

    case POST_ADD_MESSAGE_SUCCESS:
      const indexChat = state.chats.findIndex(c => c.id === action.payload.room_id)
      const message = state.messages.find(m => m.id === action.payload.id)

      state.chats[indexChat].last_message = action.payload

      // Evita mensajes de notificaciones duplicados
      if (message !== undefined) {
        return {
          ...state
        }
      } else {
        return {
          ...state,
          messages: [...state.messages, action.payload],
        }
      }

    case POST_ADD_MESSAGE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // ------------------------------------------------------------------------

    case PUT_UNVIEWED_MESSAGES_SUCCESS:
      return {
        ...state,
      }

    case PUT_UNVIEWED_MESSAGES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    // ------------------------------------------------------------------------

    case SEARCHER_CHATS:
      const tempChats = [...state.chats];
      let newTempChats

      if (action.filter !== "") {
        newTempChats = tempChats.filter(c => {
          const name = c.name.toUpperCase()
          return name.includes(action.filter.toUpperCase())
        });
      } else {
        newTempChats = tempChats
      }

      return {
        ...state,
        tempChats: newTempChats,
      }

    // ------------------------------------------------------------------------

    case SET_CURRENT_CHAT:
      return {
        ...state,
        currentChat: action.id
      }

    default:
      return state
  }
}

export default Chat
