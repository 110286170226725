import React from "react"

const AccountStatusSwitch = () => {
  return (
    <div className="d-flex align-items-center account-status">
      <div className="form-check form-switch d-flex flex-row-reverse justify-content-between">
      </div>
    </div>
  )
}

export default AccountStatusSwitch
