import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, FIREBASE_TOKEN } from "./actionTypes"
import { apiError, loginSuccess, saveFirebaseTokenSuccess } from "./actions"

import { login, saveFirebaseToken } from "../../../helpers/backend_helper"

function* loginUser({ payload: { user, history } }) {
  try {
    const response = yield call(login, {
      email: user.email,
      password: user.password,
    })
    
    localStorage.setItem("authUser", JSON.stringify(response))
    yield put(loginSuccess(response))
    history.push("/clients-list")
    window.location.reload()
  } catch (error) {
    yield put(apiError(error))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* onFirebaseToken({ payload: token} ) {
  try {
    const response = yield call(saveFirebaseToken, {token: token})
    yield put(saveFirebaseTokenSuccess(response))
  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(FIREBASE_TOKEN, onFirebaseToken)
}

export default authSaga
