import {
  GET_TRIPS,
  GET_TRIPS_FAIL,
  GET_TRIPS_SUCCESS,

  GET_TRIP,
  GET_TRIP_FAIL,
  GET_TRIP_SUCCESS,

  PUT_TRIP_PAYMENT,
  PUT_TRIP_PAYMENT_FAIL,
  PUT_TRIP_PAYMENT_SUCCESS,

  SET_ERROR,

  SEARCH_TRIP,
  SEARCH_TRIP_FAIL,
  SEARCH_TRIP_SUCCESS,

  FILTER_TRIP,
  FILTER_TRIP_FAIL,
  FILTER_TRIP_SUCCESS,
} from "./actionTypes"


// ----------------------------------------------------------------------------
// List trips
// ----------------------------------------------------------------------------

export const getTrips = (filters) => ({
  type: GET_TRIPS,
  filters
})

export const getTripsSuccess = trips => ({
  type: GET_TRIPS_SUCCESS,
  payload: trips,
})

export const getTripsFail = error => ({
  type: GET_TRIPS_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------
// Detail trip
// ----------------------------------------------------------------------------

export const getTrip = (id) => ({
  type: GET_TRIP,
  id
})

export const getTripSuccess = trip => ({
  type: GET_TRIP_SUCCESS,
  payload: trip,
})

export const getTripFail = error => ({
  type: GET_TRIP_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------
// Update trip payment
// ----------------------------------------------------------------------------

export const putTripPayment = (id, payment, validation) => ({
  type: PUT_TRIP_PAYMENT,
  id,
  payment,
  validation
})

export const putTripPaymentSuccess = trip => ({
  type: PUT_TRIP_PAYMENT_SUCCESS,
  payload: trip,
})

export const putTripPaymentFail = error => ({
  type: PUT_TRIP_PAYMENT_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------
// Trips Search
// ----------------------------------------------------------------------------

export const searchTrip = filters => ({
  type: SEARCH_TRIP,
  filters,
})

export const searchTripSuccess = trips => ({
  type: SEARCH_TRIP_SUCCESS,
  payload: trips,
})

export const searchTripFail = error => ({
  type: SEARCH_TRIP_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------
// Trips Filter
// ----------------------------------------------------------------------------

export const filterTrip = filter => ({
  type: FILTER_TRIP,
  filter,
})

export const filterTripSuccess = trips => ({
  type: FILTER_TRIP_SUCCESS,
  payload: trips,
})

export const filterTripFail = error => ({
  type: FILTER_TRIP_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------

export const setError = error => ({
  type: SET_ERROR,
  payload: error,
})
