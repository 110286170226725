import React from "react"
import PropTypes from "prop-types"

import { Row, Modal, ModalHeader, ModalBody } from "reactstrap"
import { AvForm } from "availity-reactstrap-validation"

const PhotoModal = ({ modal, toggle, imgUrl }) => {
  return (
    <Modal isOpen={modal} toggle={toggle}>
      <ModalHeader
        toggle={toggle}
        tag="h4"
        className="d-flex justify-content-center"
      >
      </ModalHeader>

      <ModalBody>
        <AvForm>
          <Row className="d-flex justify-content-center mb-3">
            <img src={imgUrl} />
          </Row>
        </AvForm>
      </ModalBody>
    </Modal>
  )
}

PhotoModal.propTypes = {
  modal: PropTypes.bool,
  toggle: PropTypes.func,
  imgUrl: PropTypes.string,
}

export default PhotoModal
