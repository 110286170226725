import {
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAIL,

  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAIL,

  PUT_CLIENT_FAIL,
  PUT_CLIENT_SUCCESS,

  SEARCH_CLIENT_FAIL,
  SEARCH_CLIENT_SUCCESS,

  FILTER_CLIENT_FAIL,
  FILTER_CLIENT_SUCCESS,

  SET_ERROR,
} from "./actionTypes"

const INIT_STATE = {
  clients: [],
  client: null,
  loading: true,
  errors: null,
}

const Clients = (state = INIT_STATE, action) => {
  switch (action.type) {

    // ----------------------------------------------------------------------------
    // List clients
    // ----------------------------------------------------------------------------

    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        clients: action.payload.data,
        loading: false
      }

    case GET_CLIENTS_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    // ----------------------------------------------------------------------------
    // get client
    // ----------------------------------------------------------------------------

    case GET_CLIENT_SUCCESS:
      return {
        ...state,
        client: action.payload.data,
        loading: false
      }

    case GET_CLIENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    // ----------------------------------------------------------------------------
    // Update client
    // ----------------------------------------------------------------------------

    case PUT_CLIENT_SUCCESS:
      const indexChat = state.clients.findIndex(c => c.id === action.payload.data.id)
      const newArray = [...state.clients];

      newArray[indexChat] = action.payload.data

      return {
        ...state,
        clients: newArray,
        client: action.payload.data,
        loading: false,
        errors: null
      }

    case PUT_CLIENT_FAIL:
      return {
        ...state,
        errors: action.payload.response.data.errors,
        loading: false
      }

    // ----------------------------------------------------------------------------
    // search clients
    // ----------------------------------------------------------------------------

    case SEARCH_CLIENT_SUCCESS:
      return {
        ...state,
        clients: action.payload.data,
        loading: false
      }

    case SEARCH_CLIENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    // ----------------------------------------------------------------------------
    // filter clients
    // ----------------------------------------------------------------------------

    case FILTER_CLIENT_SUCCESS:
      return {
        ...state,
        clients: action.payload.data,
        loading: false
      }

    case FILTER_CLIENT_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false
      }

    // ----------------------------------------------------------------------------

    case SET_ERROR:
      return {
        ...state,
        errors: action.payload,
      }
      break

    default:
      return state
  }
}

export default Clients
