import PropTypes from "prop-types"

import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import { Row, Col, Button } from "reactstrap"

import { searchAdmin } from "store/actions"

import { useDispatch } from "react-redux"

const VehicleHeader = ({ handleCreate }) => {
  const dispatch = useDispatch()

  const { t } = useTranslation()
  const [searcher, setSearcher] = useState("")
  const [searcherType, setSearcherType] = useState("charge")

  // --------------------------------------------------------------------------
  // Functions
  // --------------------------------------------------------------------------

  const onKeyPressSearcher = (e) => {
    const { key, value } = e

    if (key === "Enter") {
      // dispatch(searchAdmin(`type=admin&${searcherType}=${searcher}`))
    }
  }

  const onChangeSelect = (e) => {
    setSearcherType(e.target.value)
  }

  return (
    <div>
      <Row className="mb-2">
        <Col sm="8">
          <div className="search-box me-2 mb-2 d-inline-block">
            <h5>Lista de Vehiculos</h5>
          </div>

          {/* <div className="searcher">
            <div className="position-relative">
              <input
                type="text"
                className="form-control"
                onKeyPress={ onKeyPressSearcher }
                onChange={e => setSearcher(e.target.value)}
                placeholder={t("Search") + "..."}
              />
              <span className="bx bx-search-alt" />

              <select defaultValue="charge" className="form-select" onChange={ onChangeSelect }>
                <option value="charge">Peso Max</option>
                <option value="m3">m3</option>
                <option value="length">largo</option>
                <option value="width">ancho</option>
                <option value="height">alto</option>
              </select>
            </div>
          </div> */}
        </Col>

        <Col sm="4">
          <div>
            <Button
              type="button"
              color="primary"
              className="btn-lg btn-right mb-2 me-2"
              onClick={() => handleCreate() }
            >
              +
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

VehicleHeader.propTypes = {
  handleCreate: PropTypes.func,
  t: PropTypes.any,
}

export default VehicleHeader
