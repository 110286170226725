import React from "react"
import PropTypes from "prop-types"

import { Link, useHistory } from "react-router-dom"

import { Card, Button, NavItem, Nav, NavLink, Col, Row } from "reactstrap"

import UserAvatar from "../../../components/UserAvatar/UserAvatar"
import ClientDocumentInfo from "pages/Clients/Client-Profile/ClientSideBar/ClientDocumentInfo"

const TravelSideBar = ({ travelDetail }) => {
  const history = useHistory()
  const { client, driver, payments } = travelDetail

  const openChat = () => {
    history.push({
      pathname: "/chat",
      state: { user_id: driver.id },
    })
  }

  const scrollToDocument = (id) => {
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
    })
  }

  return (
    <Card className="email-leftbar">
      <Link to="/travel-list">
        <Row>
          <Col md="2">
            <div className="i">
              <i className="bx bx-chevron-left iconlarge"></i>
            </div>
          </Col>
          <Col>
            <h5>Viajes /Detalle de Viaje</h5>
          </Col>
        </Row>
      </Link>
      <Row>
        {<UserAvatar userDetail={client} />}

        <Button
          color="primary"
          className="btn-round h-30 w-100"
          onClick={() => {
            openChat()
          }}
        >
          <label className="label-button">Escribir Mensaje</label>
          <i className="bx bxs-comment-detail icon-small"></i>
        </Button>
      </Row>
      <Row>
        {<UserAvatar userDetail={driver} />}

        <Button
          color="primary"
          className="btn-round h-30 w-100"
          onClick={() => {
            openChat()
          }}
        >
          <label className="label-button">Escribir Mensaje</label>
          <i className="bx bxs-comment-detail icon-small"></i>
        </Button>
      </Row>

      <Row className="label-top">
        <Nav tabs className="nav-tabs-custom" vertical role="tablist">
          <NavItem>
            <NavLink onClick={() => scrollToDocument('travelDetails')}>
              <div className="d-flex justify-content-between">
                <Row>
                  <Col md="3">
                    <i className="bx bxs-truck"></i>
                  </Col>
                  <Col md="9">
                    <span className="label-nav">Datos del viaje</span>
                  </Col>
                </Row>
              </div>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink onClick={() => scrollToDocument('travelInProgress')}>
              <div className="d-flex justify-content-between">
                <Row>
                  <Col md="3">
                    <i className="bx bxs-map-pin"></i>
                  </Col>
                  <Col md="9">
                    <span className="label-nav">Progreso del viaje</span>
                  </Col>
                </Row>
              </div>
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink onClick={() => scrollToDocument('payment')}>
              <Row>
                <Col md="2">
                  <i className="bx bx-credit-card"></i>
                </Col>
                <Col md="7">
                  <span>Pagos</span>
                </Col>
              </Row>
            </NavLink>

            <ul className="sub-menu" aria-expanded="false">
              <Nav tabs className="nav-tabs-custom" vertical role="tablist">
                <NavItem>
                  <NavLink>
                    <Row>
                      {payments?.map(({ id, status, number }, index) => (
                        <div className="document" key={index}>
                          <ClientDocumentInfo
                            id={id}
                            type={
                              (number == 1 ? "Primer " : "Segundo ") + "pago"
                            }
                            status={status}
                          />
                        </div>
                      ))}
                    </Row>
                  </NavLink>
                </NavItem>
              </Nav>
            </ul>
          </NavItem>
          <NavItem>
            <NavLink onClick={() => scrollToDocument('history')}>
              <Row style={{display: 'flex', alignItems: 'center'}}>
                <Col md="2">
                  <i className="mdi mdi-clock"></i>
                </Col>
                <Col>
                  <span>Historial de ofertas</span>
                </Col>
              </Row>
            </NavLink>
          </NavItem>
        </Nav>
      </Row>
    </Card>
  )
}

TravelSideBar.propTypes = {
  travelDetail: PropTypes.object,
}

export default TravelSideBar
