import { takeEvery, put, call } from "redux-saga/effects"

import {
  GET_DOCUMENTS,
  PUT_DOCUMENT,
} from "./actionTypes"

import {
  getDocumentsSuccess,
  getDocumentsFail,

  updateDocumentSuccess,
  updateDocumentFail,
} from "./actions"

import {
  listDocuments,
  updateDocument
} from "../../helpers/backend_helper"


function* onGetDocuments({ id }) {
  try {
    const response = yield call(listDocuments, id)

    yield put(getDocumentsSuccess(response))
  } catch (error) {
    yield put(getDocumentsFail(error))
  }
}

// ----------------------------------------------------------------------------

function* onUpdateDocument({ id, data }) {
  try {
    const response = yield call(updateDocument, id, data)

    yield put(updateDocumentSuccess(response))
  } catch (error) {
    yield put(updateDocumentFail(error))
  }
}

// ----------------------------------------------------------------------------

function* DocumentsSaga() {
  yield takeEvery(GET_DOCUMENTS, onGetDocuments)
  yield takeEvery(PUT_DOCUMENT, onUpdateDocument)
}

export default DocumentsSaga
