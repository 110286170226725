import {
  GET_DRIVERS,
  GET_DRIVERS_FAIL,
  GET_DRIVERS_SUCCESS,

  PUT_DRIVER,
  PUT_DRIVER_FAIL,
  PUT_DRIVER_SUCCESS,

  SET_ERROR,

  SEARCH_DRIVER,
  SEARCH_DRIVER_FAIL,
  SEARCH_DRIVER_SUCCESS,

  FILTER_DRIVER,
  FILTER_DRIVER_FAIL,
  FILTER_DRIVER_SUCCESS,
} from "./actionTypes"


// ----------------------------------------------------------------------------
// List drivers
// ----------------------------------------------------------------------------

export const getDrivers = (filters) => ({
  type: GET_DRIVERS,
  filters
})

export const getDriversSuccess = drivers => ({
  type: GET_DRIVERS_SUCCESS,
  payload: drivers,
})

export const getDriversFail = error => ({
  type: GET_DRIVERS_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------
// Update Driver
// ----------------------------------------------------------------------------

export const updateDriver = (id, data) => ({
  type: PUT_DRIVER,
  id,
  data,
})

export const updateDriverSuccess = data => ({
  type: PUT_DRIVER_SUCCESS,
  payload: data,
})

export const updateDriverFail = error => ({
  type: PUT_DRIVER_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------
// Drivers Search
// ----------------------------------------------------------------------------

export const searchDriver = filters => ({
  type: SEARCH_DRIVER,
  filters,
})

export const searchDriverSuccess = drivers => ({
  type: SEARCH_DRIVER_SUCCESS,
  payload: drivers,
})

export const searchDriverFail = error => ({
  type: SEARCH_DRIVER_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------
// Drivers filter
// ----------------------------------------------------------------------------

export const filterDriver = filter => ({
  type: FILTER_DRIVER,
  filter,
})

export const filterDriverSuccess = drivers => ({
  type: FILTER_DRIVER_SUCCESS,
  payload: drivers,
})

export const filterDriverFail = error => ({
  type: FILTER_DRIVER_FAIL,
  payload: error,
})

// ----------------------------------------------------------------------------

export const setError = error => ({
  type: SET_ERROR,
  payload: error,
})