import React from "react"
import * as moment from "moment"
import PropTypes from "prop-types"

const TravelDescriptionLabel = ({ state, date }) => {
  const handleValidDate = date => moment(new Date(date)).format("DD/MM/Y")

  const getTitleByState = () => {
    if (state === "client_accepted_offer_at") {
      return "Oferta Aceptada "
    }
    if (state === "client_start_accepted_at") {
      return "Inicio de viaje "
    }
    return " Viaje finalizado "
  }

  const getMessageByState = () => {
    if (date) {
      return handleValidDate(date)
    } else {
      if (
        state === "client_accepted_offer_at" ||
        state === "client_start_accepted_at"
      ) {
        return "dd/mm/aaaa"
      }
      return " Viaje finalizado"
    }
  }

  return (
    <div
      className="text-dark w-75"
      style={{
        fontSize: "12px",
        fontFamily: "rubik",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <p
        style={{
          fontWeight: "500",
          padding: "0",
          margin: "0",
          fontSize: "16px",
        }}
      >
        {getTitleByState()}
      </p>
      <p style={{ padding: "0", margin: "0", fontSize: "12px" }}>
        {getMessageByState()}
      </p>
    </div>
  )
}
TravelDescriptionLabel.propTypes = {
  state: PropTypes.string,
  date: PropTypes.string,
}

export default TravelDescriptionLabel
