import { takeEvery, put, call } from "redux-saga/effects"

import {
  GET_CLIENTS,
  GET_CLIENT,
  PUT_CLIENT,
  SEARCH_CLIENT,
  FILTER_CLIENT,
} from "./actionTypes"

import {
  getClientsSuccess,
  getClientsFail,

  getClientSuccess,
  getClientFail,

  updateClientSuccess,
  updateClientFail,

  searchClientSuccess,
  searchClientFail,

  filterClientSuccess,
  filterClientFail,
} from "./actions"

import {
  listUsers,
  getUser,
  updateUser,
  searchUsers,
  filterUsers,
} from "../../helpers/backend_helper"


function* onGetClients({ filters }) {
  try {
    const response = yield call(listUsers, filters)

    yield put(getClientsSuccess(response))
  } catch (error) {
    yield put(getClientsFail(error))
  }
}

// ----------------------------------------------------------------------------

function* onGetClient({ id }) {
  try {
    const response = yield call(getUser, id)

    yield put(getClientSuccess(response))
  } catch (error) {
    yield put(getClientFail(error))
  }
}

// ----------------------------------------------------------------------------

function* onUpdateClient({ id, data }) {
  try {
    const response = yield call(updateUser, id, data)

    yield put(updateClientSuccess(response))
  } catch (error) {
    yield put(updateClientFail(error))
  }
}

// ----------------------------------------------------------------------------

function* onSearchClients({ filters }) {
  try {
    const response = yield call(searchUsers, filters)

    yield put(searchClientSuccess(response))
  } catch (error) {
    yield put(searchClientFail(error))
  }
}

// ----------------------------------------------------------------------------

function* onFilterClients({ filter }) {
  try {
    const response = yield call(filterUsers, filter)

    yield put(filterClientSuccess(response))
  } catch (error) {
    yield put(filterClientFail(error))
  }
}

function* AnalystsSaga() {
  yield takeEvery(GET_CLIENTS, onGetClients)
  yield takeEvery(GET_CLIENT, onGetClient)
  yield takeEvery(PUT_CLIENT, onUpdateClient)
  yield takeEvery(SEARCH_CLIENT, onSearchClients)
  yield takeEvery(FILTER_CLIENT, onFilterClients)
}

export default AnalystsSaga
