import {
  LIST_NOTIFICATIONS_SUCCESS,
  LIST_NOTIFICATIONS_FAIL,

  REMOVE_NOTIFICATIONS,

  NEW_NOTIFICATION_SUCCESS,
  NEW_NOTIFICATION_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  notifications: [],
  error: null
}

const Notification = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LIST_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        notifications: action.payload.data,
      }

    case LIST_NOTIFICATIONS_FAIL:
      return {
        ...state,
        new: action.payload.response.data,
      }

    // ----------------------------------------------------------------------------
    // ----------------------------------------------------------------------------

    case REMOVE_NOTIFICATIONS:
      return {
        ...state,
        notifications: state.notifications.filter(n => n.room_id !== action.chatId)
      }

    // ----------------------------------------------------------------------------
    // ----------------------------------------------------------------------------

    case NEW_NOTIFICATION_SUCCESS:
      const notification = state.notifications.find(c => c.id === action.payload.id)

      // Evita notificaciones duplicadas
      if (notification !== undefined) {
        return {
          ...state,
        }

      } else {
        return {
          ...state,
          notifications: [...state.notifications, action.payload],
        }
      }

    case NEW_NOTIFICATION_FAIL:
      return {
        ...state,
        new: action.payload.response.data,
      }

    default:
      return state
  }
}

export default Notification
