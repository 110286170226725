const API_VERSION = "/api/v1"
const API_ADMIN_VERSION = "/api/v1/admin"

// ----------------------------------------------------------------------------
// LOGIN
// ----------------------------------------------------------------------------
export const LOGIN = `${API_VERSION}/auth/login`

// ----------------------------------------------------------------------------
// CRUD Users
// ----------------------------------------------------------------------------

export const LIST_USERS = `${API_VERSION}/users`
export const CREATE_USER = `${API_VERSION}/users`
export const DETAIL_USER = `${API_VERSION}/users/`
export const UPDATE_USER = `${API_VERSION}/users/`
export const UPDATE_GENERAL_INFO_USER = `${API_VERSION}/users/`
export const SAVE_FIREBASE_TOKEN = `${API_VERSION}/users/token/firebase`

// ----------------------------------------------------------------------------
// CRUD Documents
// ----------------------------------------------------------------------------

export const LIST_DOCUMENTS = `${API_VERSION}/documents`
export const CREATE_DOCUMENT = `${API_VERSION}/documents`
export const DETAIL_DOCUMENT = `${API_VERSION}/documents`

// ----------------------------------------------------------------------------
// CRUD TRIPS
// ----------------------------------------------------------------------------

export const LIST_TRIPS = `${API_VERSION}/trips?page=1`
export const GET_TRIP = `${API_VERSION}/trips`
export const DETAIL_PAYMENT = `${API_VERSION}/trips`
export const TRIPS_SEARCHER = `${API_ADMIN_VERSION}/trips/searcher`
export const TRIPS_FILTER = `${API_ADMIN_VERSION}/trips/filter`


// ----------------------------------------------------------------------------
// Chat
// ----------------------------------------------------------------------------
export const LIST_ROOMS = `${API_ADMIN_VERSION}/chat/rooms`
export const LIST_MESSAGES = `${API_VERSION}/chat/messages`
export const LIST_UNSEEN_MESSAGES = `${API_VERSION}/chat/unseen_messages`

export const NEW_MESSAGE = `${API_VERSION}/chat/message/new`
export const NEW_ROOM = `${API_ADMIN_VERSION}/chat/new`

export const UPDATE_UNVIEWED_MESSAGES = `${API_VERSION}/chat/messages_seen`

// ----------------------------------------------------------------------------
// CRUD Admins
// ----------------------------------------------------------------------------

export const LIST_ADMINS = `${API_ADMIN_VERSION}/users`
export const UPDATE_ADMIN = `${API_ADMIN_VERSION}/users`

// ----------------------------------------------------------------------------

export const USERS_SEARCHER = `${API_ADMIN_VERSION}/search/users`
export const USERS_FILTER = `${API_ADMIN_VERSION}/users/filter`

// ----------------------------------------------------------------------------
// CRUD Vehicles
// ----------------------------------------------------------------------------

export const LIST_VEHICLES = `${API_ADMIN_VERSION}/vehicles`
export const CREATE_VEHICLE = `${API_ADMIN_VERSION}/vehicles`
export const UPDATE_VEHICLE = `${API_ADMIN_VERSION}/vehicles`
export const LIST_VEHICLE_TYPES = `${API_ADMIN_VERSION}/vehicles/types`






// ----------------------------------------------------------------------------
//
// FAKE URLS
//
// ----------------------------------------------------------------------------

//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//PRODUCTS
export const GET_PRODUCTS = "/products"
export const GET_PRODUCTS_DETAIL = "/product"

//Mails
export const GET_INBOX_MAILS = "/inboxmails"
export const ADD_NEW_INBOX_MAIL = "/add/inboxmail"
export const DELETE_INBOX_MAIL = "/delete/inboxmail"

//starred mail
export const GET_STARRED_MAILS = "/starredmails"

//important mails
export const GET_IMPORTANT_MAILS = "/importantmails"

//Draft mail
export const GET_DRAFT_MAILS = "/draftmails"

//Send mail
export const GET_SENT_MAILS = "/sentmails"

//Trash mail
export const GET_TRASH_MAILS = "/trashmails"

//CALENDER
export const GET_EVENTS = "/events"
export const ADD_NEW_EVENT = "/add/event"
export const UPDATE_EVENT = "/update/event"
export const DELETE_EVENT = "/delete/event"
export const GET_CATEGORIES = "/categories"

//CHATS
export const GET_CONTACTS = "/contacts"

//ORDERS
export const GET_ORDERS = "/orders"
export const ADD_NEW_ORDER = "/add/order"
export const UPDATE_ORDER = "/update/order"
export const DELETE_ORDER = "/delete/order"

//CART DATA
export const GET_CART_DATA = "/cart"

//CUSTOMERS
export const GET_CUSTOMERS = "/customers"
export const ADD_NEW_CUSTOMER = "/add/customer"
export const UPDATE_CUSTOMER = "/update/customer"
export const DELETE_CUSTOMER = "/delete/customer"

//SHOPS
export const GET_SHOPS = "/shops"

//CRYPTO
export const GET_WALLET = "/wallet"
export const GET_CRYPTO_ORDERS = "/crypto/orders"

//INVOICES
export const GET_INVOICES = "/invoices"
export const GET_INVOICE_DETAIL = "/invoice"

//PROJECTS
export const GET_PROJECTS = "/projects"
export const GET_PROJECT_DETAIL = "/project"
export const ADD_NEW_PROJECT = "/add/project"
export const UPDATE_PROJECT = "/update/project"
export const DELETE_PROJECT = "/delete/project"

//TASKS
export const GET_TASKS = "/tasks"

//CONTACTS
export const GET_USERS = `${API_VERSION}/users`
export const GET_USER_PROFILE = "/user"
export const ADD_NEW_USER = "/add/user"
export const DELETE_USER = "/delete/user"

//dashboard charts data
export const GET_WEEKLY_DATA = "/weekly-data"
export const GET_YEARLY_DATA = "/yearly-data"
export const GET_MONTHLY_DATA = "/monthly-data"

export const TOP_SELLING_DATA = "/top-selling-data"

export const GET_EARNING_DATA = "/earning-charts-data"

export const GET_PRODUCT_COMMENTS = "/comments-product"

export const ON_LIKNE_COMMENT = "/comments-product-action"

export const ON_ADD_REPLY = "/comments-product-add-reply"

export const ON_ADD_COMMENT = "/comments-product-add-comment"
